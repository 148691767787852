import {FC} from 'react';
import {TopFiveDataComponent} from '../../../components/TopFiveDataComponent';
import {StatisticsWidget5} from '../../../../_metronic/partials/widgets';
import {OverAllSummary} from '../../../../data/models/OverAllSummary';

type SummaryOverviewProps = {
  overAllSummary: OverAllSummary;
};

const SummaryOverview: FC<SummaryOverviewProps> = ({overAllSummary}) => {
  const top5incident = overAllSummary.topFiveIncident.map((el) => {
    return {dataKey: el.description, value: el.times};
  });
  const top5incidentByCarriages = overAllSummary.topIncidentByCarriages.map((el) => {
    return {dataKey: el.description, value: el.times};
  });
  return (
    <>
      <div className='row row-xxl-12 g-3 m-0 p-0'>
        <div className='col col-xxl-6'>
          <div className='row row-cols-4 g-2 min-w-450px mb-1'>
            <div className='col'>
              <StatisticsWidget5
                className='card-xl-stretch mb-8'
                svgIcon='chart-simple'
                color='body-white'
                iconColor='primary'
                title={`${overAllSummary.needMaintenanceTrainSet}${'\n'}`}
                titleSize='fs-2'
                secondTitle='Gerbong'
                description='Butuh Pemeriksaan'
                titleColor='gray-900'
                descriptionColor='gray-400'
              />
            </div>
            <div className='col'>
              <StatisticsWidget5
                className='card-xl-stretch mb-8'
                svgIcon='chart-simple'
                color='body-white'
                iconColor='primary'
                title={`${overAllSummary.amountCheckedTrainSet}`}
                titleSize='fs-2'
                subTitle={`/${overAllSummary.numberOfAllTrainSet}`}
                secondTitle='Trainset'
                description='Total TS Terperiksa'
                titleColor='gray-900'
                descriptionColor='gray-400'
              />
            </div>
            <div className='col'>
              <StatisticsWidget5
                className='card-xl-stretch mb-8'
                svgIcon='chart-simple'
                color='body-white'
                iconColor='primary'
                title={`${overAllSummary.safetinessPercentation}%`}
                titleSize='fs-2'
                secondTitle='Aman'
                description='Total Persentase Kondisi Gerbong'
                titleColor='gray-900'
                descriptionColor='gray-400'
              />
            </div>
            <div className='col'>
              <StatisticsWidget5
                className='card-xl-stretch mb-8'
                svgIcon='chart-simple'
                color='body-white'
                iconColor='primary'
                title={`${overAllSummary.panelTemperature}°C`}
                titleSize='fs-2'
                secondTitle='Suhu Panel'
                description='Update terkini suhu panel hotbox '
                titleColor='gray-900'
                descriptionColor='gray-400'
              />
            </div>
          </div>
          <div className='row row-cols-3 g-2 min-w-450px'>
            <div className='col'>
              <StatisticsWidget5
                className='card-xl-stretch mb-8'
                svgIcon='chart-simple'
                color='body-white'
                iconColor='primary'
                title={`${overAllSummary.axleSummaryTemperature}°C`}
                description='Rata-Rata suhu Axle'
                titleColor='gray-900'
                descriptionColor='gray-400'
              />
            </div>
            <div className='col'>
              <StatisticsWidget5
                className='card-xl-stretch mb-8 mx-1'
                svgIcon='chart-simple'
                color='body-white'
                iconColor='primary'
                title={`${overAllSummary.wheelSummaryTemperature}°C`}
                description='Rata-Rata suhu Wheel'
                titleColor='gray-900'
                descriptionColor='gray-400'
              />
            </div>
            <div className='col'>
              <StatisticsWidget5
                className='card-xl-stretch mb-8'
                svgIcon='chart-simple'
                color='body-white'
                iconColor='primary'
                title={`${overAllSummary.brakeSummaryTemperature}°C`}
                description='Rata-Rata suhu Brake'
                titleColor='gray-900'
                descriptionColor='gray-400'
              />
            </div>
          </div>
        </div>
        <div className='col col-xxl-6'>
          <div className='row g-3 align-self-stretch'>
            <div className='col col-md-6 '>
              <TopFiveDataComponent
                model={{
                  title: 'Top 5 Insiden',
                  description: 'Data deskripsi',
                  data: top5incident,
                }}
              />
            </div>
            <div className='col col-md-6'>
              <TopFiveDataComponent
                model={{
                  title: 'Insiden berdasarkan gerbong',
                  description: 'Data deskripsi',
                  data: top5incidentByCarriages,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export {SummaryOverview};
