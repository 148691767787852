export type State<T = {}> = {
    status: StateStatus,
    data?: T,
    message?: string,
}

export enum StateStatus {
    LOADING,
    LOADED,
    ERROR,
}