import {FC} from 'react';
import {getTimeText} from '../../../common/Utils';

type Props = {
  date?: Date;
};
const NotificationTimeStamp: FC<Props> = ({date = new Date(Date.now())}) => {
  return (
    <>
      <div className='fs-7 text-gray-600'>
        Data capture {date.toLocaleDateString('id', {dateStyle: 'medium'})} |{' '}
        {getTimeText(date.getHours())}:{getTimeText(date.getMinutes())} WIB
      </div>
    </>
  );
};

export {NotificationTimeStamp};
