import {StatusData, StatusDefinition} from '../data/models/enums/status';
import {MONTH_NAMES} from './CommonConstants';

export const tryParse = (json: string): any | undefined => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return undefined;
  }
};

export const randomKeyNumber = Math.floor(Math.random() * 99999);

export const getNextFocusedIndex = (current: number, maxLength: number) => {
  if (current === maxLength - 1) {
    return 0;
  }
  return current + 1;
};
export const generateDateTimeText = (dateTime: Date): string => {
  const _createDate = () => {
    return `${dateTime.getDate()} ${_createMonth()} ${dateTime.getFullYear()}`;
  };

  const _createMonth = () => {
    const monthNames = MONTH_NAMES;
    return monthNames[dateTime.getMonth()];
  };

  const _createTime = () => {
    return `${getTimeText(dateTime.getHours())}:${getTimeText(dateTime.getMinutes())}`;
  };

  return `${_createDate()} Jam ${_createTime()}`;
};

export const getTimeText = (time: number): string => {
  if (time.toString().length > 1) {
    return time.toString();
  }
  return `0${time}`;
};

export const getColorStatusDefinition = (status: StatusDefinition) => {
  switch (status) {
    case StatusDefinition.SAFE:
      return 'primary';
    case StatusDefinition.WARNING:
      return 'warning';
    case StatusDefinition.DANGER:
      return 'danger';
    default:
      return 'gray-600';
  }
};

export const getColorStatusData = (status: StatusData) => {
  switch (status) {
    case StatusData.DONE:
      return 'primary';
    case StatusData.FAILED:
      return 'danger';
    default:
      return 'dark';
  }
};
