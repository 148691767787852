import {FC, useState} from 'react';
import {CustomDropdown, IDropdownProp} from '../../components/Dropdown';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers';
import {AssetsConstant} from '../../../common/AssetsConstants';

const LogTableHeader: FC = () => {
  const dropdownCallback = (selected: IDropdownProp) => {
    setActiveDropdown(selected);
  };
  const dropdownValues: IDropdownProp[] = [
    {title: 'Semua Trainset', action: dropdownCallback},
    {title: 'TS1', action: dropdownCallback},
    {title: 'TS2', action: dropdownCallback},
  ];
  const [activeDropdown, setActiveDropdown] = useState<IDropdownProp>(dropdownValues[0]);
  const [dateState, setDateState] = useState<{startDate: Date; endDate: Date}>({
    startDate: new Date(),
    endDate: new Date(),
  });
  console.log(dateState);
  return (
    <>
      <div className='hstack gap-3'>
        <div>
          <h1 className='fs-2'>Time Log</h1>
        </div>
        <div className='ms-auto'>
          <CustomDropdown props={dropdownValues} activeProp={activeDropdown} />
        </div>
        <div className='card w-250px hstack bg-white border-0'>
          <Flatpickr
            onChange={([startDate, endDate]) => {
              setDateState({startDate: startDate, endDate: endDate});
            }}
            options={{
              mode: 'range',
              formatDate: (date, format, local) => {
                return date.toLocaleDateString('id', {dateStyle: 'medium'});
              },
            }}
            style={{fontWeight: 'bolder'}}
            className='form-control form-control-solid h-45px bg-white border-0'
            placeholder='Pick date'
            height={50}
          />
          <div className='badge badge-gray-400 me-3'>
            <i className='fs-4'>
              <KTSVG className='svg-icon-2x' path={toAbsoluteUrl(AssetsConstant.calendar)} />
            </i>
          </div>
        </div>

        <div
          className='btn py-4 fw-bold h-45px text-white'
          style={{background: 'linear-gradient(93deg, #52525C 0%, black 100%)'}}
        >
          Search
        </div>
      </div>
    </>
  );
};

export {LogTableHeader};
