import {useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {HeaderWrapper} from './components/header/HeaderWrapper';
import {ScrollTop} from './components/ScrollTop';
import {Content} from './components/Content';
import {PageDataProvider} from './core';
import {DrawerMessenger, InviteUsers, UpgradePlan} from '../partials';
import {MenuComponent} from '../assets/ts/components';
import { LogoutModal } from '../../presentation/components/modals/LogoutModal';

const MasterLayout = () => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  return (
    <PageDataProvider>
      {/* <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
      </div> */}

      <div className='wrapper d-flex flex-column' id='kt_wrapper'>
        <HeaderWrapper />
        <div id='kt_content' className='content p-0 bg-gray-300'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            <Content>
              <Outlet />
            </Content>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
      {/* begin:: Drawers */}
      {/* <ActivityDrawer /> */}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <InviteUsers />
      <UpgradePlan />
      <LogoutModal />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  );
};

export {MasterLayout};
