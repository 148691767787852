import {INotification} from './Notification';

export const notificationTestData: INotification[] = [
  {
    id: '1',
    dataId: '1',
    trainsetName: 'TS 1',
    captureDate: new Date(2024, 0, 2, 17, 6),
    isRead: false,
  },
  {
    id: '2',
    dataId: '2',
    trainsetName: 'TS 2',
    captureDate: new Date(2024, 0, 2, 17, 20),
    isRead: true,
  },
  {
    id: '3',
    dataId: '3',
    trainsetName: 'TS 1',
    captureDate: new Date(2024, 0, 2, 17, 25),
    isRead: true,
  },
  {
    id: '4',
    dataId: '4',
    trainsetName: 'TS 2',
    captureDate: new Date(2024, 0, 2, 18, 20),
    isRead: true,
  },
  {
    id: '5',
    dataId: '5',
    trainsetName: 'TS 1',
    captureDate: new Date(2024, 0, 2, 18, 25),
    isRead: true,
  },
  {
    id: '6',
    dataId: '6',
    trainsetName: 'TS 2',
    captureDate: new Date(2024, 0, 2, 19, 20),
    isRead: true,
  },
  {
    id: '7',
    dataId: '7',
    trainsetName: 'TS 1',
    captureDate: new Date(2024, 0, 2, 19, 25),
    isRead: true,
  },
];
