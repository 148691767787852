import {FC} from 'react';
import {KTSVG} from '../../../_metronic/helpers';
import {CommonConstant} from '../../../common/CommonConstants';

export enum SubmitState {
  success,
  failed,
  idle,
}

type Props = {
  isLoading: boolean;
  isDataValid?: boolean;
  state: SubmitState;
  onClick: () => void;
};

const SubmitButton: FC<Props> = ({isLoading, state, onClick, isDataValid = true}) => {
  return (
    <>
      {state === SubmitState.success && (
        <button className='btn btn-success fw-bold' disabled={true}>
          {CommonConstant.buttonSaved}
          <KTSVG
            path='/media/icons/duotune/arrows/arr020.svg'
            className='svg-icon-white fs-3 ms-2'
          />
        </button>
      )}
      {state === SubmitState.idle && (
        <button
          className='btn btn-primary fw-bold'
          onClick={() => {
            onClick();
          }}
          disabled={isLoading || !isDataValid}
        >
          {!isLoading && CommonConstant.buttonSave}
          {isLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {CommonConstant.buttonSaving}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      )}
    </>
  );
};

export {SubmitButton};
