import {FC} from 'react';
import {SummaryOverview} from './SummaryOverview';
import {OverAllSummary} from '../../../../data/models/OverAllSummary';

type Props = {
  data: OverAllSummary;
};

const OverAllDashboard: FC<Props> = ({data}) => {
  return (
    <div className='container p-5 bg-gray-300'>
      <SummaryOverview overAllSummary={data} />
    </div>
  );
};

export {OverAllDashboard};
