import {INotification} from '../../data/models/notification/Notification';
import {NotificationRepository} from '../../data/repositories/NotificationRepository';

const getAll = (): INotification[] => {
  return NotificationRepository.getAll();
};

const createNotification = () => {
  NotificationRepository.createNotification();
};

const updateRead = (id: string) => {
  NotificationRepository.updateRead(id);
};
export const NotificationUsecase = {
  getAll,
  createNotification,
  updateRead,
};
