import {FC, ReactElement} from 'react';
import {State, StateStatus} from './State';

type Prop = {
  state: State;
  loadingBuilder: () => ReactElement;
  loadedBuilder: () => ReactElement | JSX.Element;
  errorBuilder: () => ReactElement;
};
const StateBuilder: FC<Prop> = ({state, loadingBuilder, loadedBuilder, errorBuilder}) => {
  switch (state.status) {
    case StateStatus.LOADED:
      return <>{loadedBuilder()}</>;
    case StateStatus.ERROR:
      return <>{errorBuilder()}</>;
    default:
      return <>{loadingBuilder()}</>;
  }
};

export {StateBuilder};
