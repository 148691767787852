const firstTrain = '/media/svg/first-train.svg';
const error = '/media/svg/error.svg';
const warning = '/media/svg/warning.svg';
const onProcess = '/media/svg/on-progress.svg';
const imamLogo = '/media/svg/imam-logo.svg';
const imamAuthLogo = '/media/misc/imam-auth-logo.png';
const lastTrain = '/media/svg/last-train.svg';
const middleTrain = '/media/svg/middle-train.svg';
const calendar = '/media/svg/calendar.svg';
const notificationAlert = '/media/svg/notification-alert.svg';

export const AssetsConstant = {
  firstTrain,
  middleTrain,
  lastTrain,
  error,
  warning,
  onProcess,
  imamLogo,
  imamAuthLogo,
  calendar,
  notificationAlert,
};
