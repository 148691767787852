import { jsPDFOptions } from "jspdf";

export const DUMMY_SOCKET_URL = 'wss://socketsbay.com/wss/v2/1/demo/';
export const DEFAULT_TIMER = 60;

export const MONTH_NAMES = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
];

export const PDF_SETUP: jsPDFOptions = {orientation: 'p', unit: 'cm', format: [70, 85]};

const buttonSave = 'Simpan';
const buttonSaving = 'Menyimpan...';
const buttonSaved = 'Tersimpan';
const buttonAddRFID = 'Add New RFID';

export const CommonConstant = {
  buttonSave,
  buttonAddRFID,
  buttonSaving,
  buttonSaved,
};
