import {FC} from 'react';
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers';
import {AssetsConstant} from '../../../common/AssetsConstants';
import {NotificationTimeStamp} from '../../components/time-component/NotificationTimeStamp';
import {Link} from 'react-router-dom';
import {hotBoxRoutes} from '../../../common/Routes';

type Props = {
  isShowNotif: boolean;
  notificationDate?: Date;
};
const NotificationBanner: FC<Props> = ({isShowNotif, notificationDate}) => {
  return (
    <>
      <div className='ribbon-label bg-transparent shadow-none fw-bold mt-10'>
        <Link to={{pathname: hotBoxRoutes.notificationRoute.path}}>
          <div
            className={`btn ${
              isShowNotif
                ? 'btn-custom-bg-primary border border-1 border-primary w-350px'
                : 'btn-transparent border border-0 border-primary'
            } p-0 `}
            style={{borderRadius: 12}}
          >
            {isShowNotif && <NotificationBody newDataDate={notificationDate} />}
          </div>
        </Link>
      </div>
    </>
  );
};

type NotificationBodyProps = {
  newDataDate?: Date;
};
const NotificationBody: FC<NotificationBodyProps> = ({newDataDate}) => {
  return (
    <>
      <div className='row row-sm-12 m-4 align-items-center'>
        <div className='col col-sm-2 ps-0 me-2'>
          <KTSVG className='svg-icon-4x' path={toAbsoluteUrl(AssetsConstant.notificationAlert)} />
        </div>
        <div className='col'>
          <div className='d-flex flex-column'>
            <div className='d-flex justify-content-start fs-3 text-dark fw-bold'>Data Terbaru</div>
            <div className='d-flex justify-content-start fw-bold'>
              <NotificationTimeStamp date={newDataDate}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export {NotificationBanner};
