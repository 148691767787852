import {FC} from 'react';
import {PageHeader} from '../../components/PageHeader';
import {TrainSetSettingList} from './TrainSetSettingList';

const SettingsWrapper: FC = () => {
  return (
    <>
      <div className='container'>
        <div className='mt-7'></div>
        <PageHeader title='Pegaturan'/>
        <div className='mt-10'></div>
        <TrainSetSettingList />
        <div className='separator mb-20'></div>
      </div>
    </>
  );
};

export {SettingsWrapper};
