import { TrainSet, toTrainsetFromResponse } from '../../data/models/TrainSet';
import {TrainSetTimeLogRepository} from '../../data/repositories/TrainSetTimeLogRepository';

const getAll = () => {
  return TrainSetTimeLogRepository.getAll();
};

const getById = async (id: string): Promise<TrainSet> => {
  const result = await TrainSetTimeLogRepository.getByTrainsetId(id);
  return toTrainsetFromResponse(result);
};

export const TrainSetTimeLogUsecase = {
  getAll,
  getById,
};
