import {FC, useEffect, useRef, useState} from 'react';
import noUiSlider, {target} from 'nouislider';
import {Stack} from 'react-bootstrap';
import {ITemperatureLimit} from '../../../../data/models/settings/HotboxSetting';

type Props = {
  title: string;
  partitionSetting?: ITemperatureLimit;
  onChange: (partitionSetting: ITemperatureLimit) => void;
};

const TrainsetAlertPartitionForm: FC<Props> = ({title, partitionSetting, onChange}) => {
  const sliderName = `slider_${title}`;
  const [isSliderInitialize, setIsSliderInitialize] = useState<boolean>(false);
  const {bottomSafe, topSafe, topWarning} = partitionSetting ?? {};
  const retrievedLoad = useRef(false);
  const [temperatureSetups, setTemperatureSetups] = useState<number[]>([
    bottomSafe ?? 0,
    topSafe ?? 32,
    topWarning ?? 50,
  ]);

  useEffect(() => {
    if (
      !retrievedLoad.current &&
      !([bottomSafe, topSafe, topWarning].findIndex((el) => el === undefined) !== -1)
    ) {
      retrievedLoad.current = true;
      setTemperatureSetups([bottomSafe ?? 0, topSafe ?? 32, topWarning ?? 50]);
    }
  }, [bottomSafe, partitionSetting, temperatureSetups, topSafe, topWarning]);

  useEffect(() => {
    const slider: target = document.getElementById(`${sliderName}`) as target;
    try {
      if (slider.noUiSlider?.get().toString() !== temperatureSetups.toString()) {
        slider.noUiSlider?.set(temperatureSetups);
      }
    } catch (e) {
      console.error(e);
    }
    if (!isSliderInitialize) {
      if (!slider) {
        return;
      }

      slider.innerHTML = '';
      noUiSlider.create(slider, {
        start: temperatureSetups,
        step: 1,
        tooltips: [true, true, true],
        connect: [true, true, true, true],
        range: {
          min: [-20],
          max: [100],
        },
        format: {
          to: (v) => parseFloat(v.toString()).toFixed(0),
          from: (v: string) => Number(v),
        },
      });
      setIsSliderInitialize(true);
      var connect = slider.querySelectorAll('.noUi-connect');
      var touchAreas = slider.querySelectorAll('.noUi-handle');
      var classes = [
        'bg-danger-custom-2',
        'bg-primary',
        'bg-warning',
        'bg-danger-custom-2',
        'c-5-color',
      ];
      var touchClasses = ['bg-danger-custom-2'];

      for (var i = 0; i < connect.length; i++) {
        connect[i].classList.add(classes[i]);
      }
      touchAreas[0].classList.add(touchClasses[0]);
      touchAreas[2].classList.add('bg-warning');

      slider.noUiSlider?.on('update', function (values: any, handle: any) {
        setTemperatureSetups(values);
        onChange({bottomSafe: values[0], topSafe: values[1], topWarning: values[2]});
      });
    }
  }, [isSliderInitialize, sliderName, temperatureSetups, onChange]);

  return (
    <>
      <div className='card bg-gray-200 border-0 my-4 p-7'>
        <div className='fs-3 fw-bolder'>{title}</div>
        <div className='my-10'>
          <div id={`${sliderName}`} className='noUi-target noUi-target-primary noUi-sm'></div>
        </div>
        <div className='row row-xl-12'>
          <div className='col col-xl-6'>
            <Legend
              title='Aman'
              description='Condimentum praesent auctor fringilla purus ut.'
              bulletColor={'bg-primary'}
            />
          </div>
          <div className='col col-xl-6'>
            <Stack direction='horizontal' gap={3}>
              <div className='input-group'>
                <input
                  id='safe_bottom'
                  type='number'
                  className='form-control'
                  placeholder='Batas Bawah'
                  aria-label='Aman batas Bawah'
                  aria-describedby='basic-addon2'
                  onChange={(event) => {
                    setTemperatureSetups([
                      Number(event.target.value),
                      temperatureSetups[1],
                      temperatureSetups[2],
                    ]);
                    onChange({
                      bottomSafe: Number(event.target.value),
                      topSafe: temperatureSetups[1],
                      topWarning: temperatureSetups[2],
                    });
                  }}
                  value={temperatureSetups[0]}
                />
                <div className='input-group-append'>
                  <span className='input-group-text' id='basic-addon2'>
                    °C
                  </span>
                </div>
              </div>
              <div className='w-25px h-2px bg-gray-800'></div>
              <div className='input-group'>
                <input
                  id='safe_top'
                  type='number'
                  className='form-control'
                  placeholder='Batas Atas'
                  aria-label='Aman Batas Atas'
                  aria-describedby='basic-addon2'
                  value={temperatureSetups[1]}
                  onChange={(event) => {
                    setTemperatureSetups([
                      temperatureSetups[0],
                      Number(event.target.value),
                      temperatureSetups[2],
                    ]);
                    onChange({
                      bottomSafe: temperatureSetups[0],
                      topSafe: Number(event.target.value),
                      topWarning: temperatureSetups[2],
                    });
                  }}
                />
                <div className='input-group-append'>
                  <span className='input-group-text' id='basic-addon2'>
                    °C
                  </span>
                </div>
              </div>
            </Stack>
          </div>
        </div>
        <div className='row row-xl-12 my-10 '>
          <div className='col'>
            <Legend
              title='Peringatan'
              description='Posuere in ornare bibendum vitae mauris amet.'
              bulletColor={'bg-warning'}
            />
          </div>
          <div className='col'>
            <Stack direction='horizontal' gap={3}>
              <div className='input-group'>
                <input
                  type='number'
                  className='form-control'
                  placeholder='Batas Bawah'
                  aria-label='warning batas bawah'
                  aria-describedby='basic-addon2'
                  disabled={true}
                  value={temperatureSetups[1]}
                />
                <div className='input-group-append'>
                  <span className='input-group-text' id='basic-addon2'>
                    °C
                  </span>
                </div>
              </div>
              <div className='w-25px h-2px bg-gray-800'></div>
              <div className='input-group'>
                <input
                  id='warning_top'
                  type='number'
                  className='form-control'
                  placeholder='Batas Atas'
                  aria-label='warning batas bawah'
                  aria-describedby='basic-addon2'
                  value={temperatureSetups[2]}
                  onChange={(event) => {
                    setTemperatureSetups([
                      temperatureSetups[0],
                      temperatureSetups[1],
                      Number(event.target.value),
                    ]);
                    onChange({
                      bottomSafe: temperatureSetups[0],
                      topSafe: temperatureSetups[1],
                      topWarning: Number(event.target.value),
                    });
                  }}
                />
                <div className='input-group-append'>
                  <span className='input-group-text' id='basic-addon2'>
                    °C
                  </span>
                </div>
              </div>
            </Stack>
          </div>
        </div>
        <div className='row row-xl-12'>
          <div className='col col-xl-6'>
            <Legend
              title='Bahaya'
              description='Velit convallis in tellus orci ac suscipit fringilla.'
              bulletColor={'bg-danger'}
            />
          </div>
          <div className='col col-xl-6'>
            <Stack direction='horizontal' gap={3}>
              <div className='w-250px'>Kurang dari:</div>
              <div className='input-group'>
                <input
                  type='number'
                  className='form-control'
                  placeholder='0'
                  aria-label='danger batas bawah'
                  aria-describedby='basic-addon2'
                  disabled={true}
                  value={temperatureSetups[0]}
                />
                <div className='input-group-append'>
                  <span className='input-group-text' id='basic-addon2'>
                    °C
                  </span>
                </div>
              </div>
              <div className='w-250px'>Lebih dari:</div>
              <div className='input-group'>
                <input
                  type='number'
                  className='form-control'
                  placeholder='Batas Atas'
                  aria-label='danger Batas atas'
                  aria-describedby='basic-addon1'
                  disabled={true}
                  value={temperatureSetups[2]}
                />
                <div className='input-group-append'>
                  <span className='input-group-text' id='basic-addon2'>
                    °C
                  </span>
                </div>
              </div>
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

type LegendType = {
  title: string;
  description: string;
  bulletColor: string;
};
const Legend: FC<LegendType> = ({title, description, bulletColor}) => {
  return (
    <>
      <div>
        <div className='d-flex align-items-center fw-bold me-15 text-gray-700 fs-6'>
          <span className={`bullet bullet-dot ${bulletColor} h-10px w-10px me-5`}></span>
          {title}
        </div>
        <div className='text-gray-500 fs-7'>{description}</div>
      </div>
    </>
  );
};

export {TrainsetAlertPartitionForm};
