import {FC, useState, useCallback, useEffect} from 'react';
import {TrainCarriagesCard} from '../../../components/TrainCarriagesCard';
import {TrainSetSummary} from '../../../components/TrainSetSummary';
import {TrainSetDashboardHeader} from './TrainSetDashboardHeader';
import {InvalidDataComponent, InvalidDataType} from '../../../components/InvalidDataComponent';
import {TrainSetListDashboardItem} from '../../../../data/models/Dashboard';
import {DashboardUsecase} from '../../../../domain/usecases/DashboardUsecase';

type Props = {
  data: TrainSetListDashboardItem[];
  isForDetailPage?: boolean;
};

export const TrainSetDashboard: FC<Props> = ({data, isForDetailPage = false}) => {
  const [dashboardData, setDashboardData] = useState<TrainSetListDashboardItem[]>(data);
  const [trainSet, setTrainSet] = useState<TrainSetListDashboardItem>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    setDashboardData(data);

    if (data.length !== 0) setTrainSet(data[0]);
  }, [data, setDashboardData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const refetchData = async (index: number) => {
    setIsLoading(true);
    const newData = await DashboardUsecase.getAllData();
    setIsLoading(false);
    setDashboardData(newData.tranSets);
    setTrainSet(newData.tranSets[index]);
  };

  const errorComponent = [];
  if (!trainSet?.trainSet && trainSet?.isError === true) {
    errorComponent.push(
      <div className='bg-gray-300 pt-20 w-1500px h-400px'>
        <InvalidDataComponent type={InvalidDataType.FAILED} />
      </div>
    );
  } else if (!trainSet?.trainSet && trainSet?.isError === false) {
    errorComponent.push(<DataStillProcessingScreen />);
  }

  if (trainSet) {
    const trainCarriagesCards =
      trainSet.trainSet?.carriages.map((carriages) => {
        return (
          <TrainCarriagesCard
            key={carriages.id}
            trainCarriages={carriages}
            length={trainSet.trainSet!.carriages.length}
            index={trainSet.trainSet!.carriages.indexOf(carriages)}
          />
        );
      }) ?? [];
    return (
      <>
        <div className='bg-gray-300 p-5'>
          {!isForDetailPage && (
            <TrainSetDashboardHeader
              trainSets={dashboardData}
              onRestartTimer={(nextIndex) => {
                refetchData(nextIndex);
              }}
              onTapCard={(nextTrainSet: TrainSetListDashboardItem) => {
                setTrainSet(nextTrainSet);
              }}
            />
          )}
          <div className='spacer border-0 my-4'></div>
          {isLoading && (
            <div className='bg-gray-300 pt-20 w-1500px h-400px'>
              <InvalidDataComponent type={InvalidDataType.DATA_STILL_PROCESSING} />
            </div>
          )}
          {!isLoading && (
            <>
              <TrainSetSummary trainSet={trainSet.trainSet} />
              {errorComponent}
              <div className='row g-3'>{trainCarriagesCards}</div>
            </>
          )}
        </div>
      </>
    );
  } else {
    return (
      <>
        <DataStillProcessingScreen key={`data_not_ready_yet_${Math.random()}`} />
      </>
    );
  }
};

const DataStillProcessingScreen = () => {
  return (
    <>
      <div className='bg-gray-300 pt-20 w-1500px h-400px'>
        <InvalidDataComponent key='data_not_ready_yet' type={InvalidDataType.DATA_NOT_READY_YET} />
      </div>
    </>
  );
};
