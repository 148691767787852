import {FC} from 'react';
import {PageHeader} from '../../components/PageHeader';
import { AccountWrapper } from './AccountWrapper';

const UserProfileWrapper: FC = () => {
  return (
    <>
      <div className='container-xxl'>
        <div className='mt-7'></div>
        <PageHeader title={'Akun'} />
        <div className='mt-10'></div>
        <AccountWrapper />
        {/* <ProfileDetailsForm /> */}
        <div className='separator mb-20 border-0'></div>
      </div>
    </>
  );
};

export {UserProfileWrapper};
