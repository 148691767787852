import {FC} from 'react';
import {NotificationTabCard} from './NotificationTabCard';
import {PageHeader} from '../../components/PageHeader';
import {NotificationConstant} from './NotificationConstant';

const NotificationWrapper: FC = () => {
  return (
    <>
      <div className='container-xxl'>
        <div className='mt-7'></div>
        <PageHeader title={NotificationConstant.pageTitle} />
        <div className='mt-10'></div>
        <NotificationTabCard />
        <div className='separator mb-20 border-0'></div>
      </div>
    </>
  );
};

export {NotificationWrapper};
