import {toAbsoluteUrl} from '../../_metronic/helpers';
import {AssetsConstant} from '../../common/AssetsConstants';
import {randomKeyNumber} from '../../common/Utils';
import {TrainCarrieges} from '../../data/models/TrainCarriages';
import {WheelDetailComponent} from './WheelDetailComponent';
import {FC} from 'react';

type TrainCarriagesCardProperty = {
  trainCarriages: TrainCarrieges;
  index: number;
  length: number;
};

const TrainCarriagesCard: FC<TrainCarriagesCardProperty> = ({trainCarriages, index, length}) => {
  const wheelCards = [];
  for (var i = 0; i < trainCarriages.wheels.length; i++) {
    wheelCards.push(
      <WheelDetailComponent
        key={`wheel-detail_${i}_${randomKeyNumber}`}
        wheelDetail={trainCarriages.wheels[i]}
        wheelNumber={i + 1}
      />
    );
  }

  const _getImagePath = (): string => {
    if (index === 0) return toAbsoluteUrl(AssetsConstant.firstTrain);
    if (index === length - 1) return toAbsoluteUrl(AssetsConstant.lastTrain);
    return toAbsoluteUrl(AssetsConstant.middleTrain);
  };

  return (
    <>
      <div className='col-sm-2 max-w-200px min-w-175px'>
        <div className='card card-custom shadow mb-5 p-0'>
          <div className='separator border-0 my-3'></div>
          <div className='card-body d-flex flex-center flex-column p-0'>
            <div className='symbol symbol-45px symbol-1by3 mb-0'>
              <img src={_getImagePath()} alt='' />
            </div>
            <div className='fs-6 fw-bold text-gray-700'>{trainCarriages.id}</div>
          </div>
          <div className='separator border-0 my-1'></div>
          {wheelCards}
        </div>
      </div>
    </>
  );
};

export {TrainCarriagesCard};
