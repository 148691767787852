const tabTitleTrainsetData = 'Trainset Data';
const tabTilteRFIDData = 'RFID Data';
const tabTilteLicense = 'License';
const partTitleAxle = 'Axle';
const partTitleWheel = 'Wheel';
const partTitleBreak = 'Break';
const addNewButton = 'Add New';

export const SettingConstant = {
  tabTilteRFIDData,
  tabTilteLicense,
  tabTitleTrainsetData,
  partTitleAxle,
  partTitleWheel,
  partTitleBreak,
  addNewButton,
};
