import {FC, useEffect, useState} from 'react';
import {generateDateTimeText} from '../../../common/Utils';
import {TimePresentation} from './TimePresentation';

const DateTimeNowComponent: FC = () => {
  const [now, setNow] = useState<number>(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
        setNow(Date.now());
    }, 1000);
    return () => clearInterval(interval);
  }, [setNow]);

  return (
    <>
      <TimePresentation className={'fs-2 text-dark fw-bolder'} timeText={generateDateTimeText(new Date(now))} />
    </>
  );
};

export {DateTimeNowComponent};
