import React from 'react';
import {SettingConstant} from '../settings/SettingConstant';
import {LicenseWrapper} from './LicenseWrapper';
import { ProfileDetailsForm } from './ProfileDetailsForm';

const AccountWrapper: React.FC = () => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-bottom'>
        {/* <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div> */}
        <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-3x mt-5 fs-2'>
          <li className='nav-item'>
            <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
              Profile Details
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
              {SettingConstant.tabTilteLicense}
            </a>
          </li>
        </ul>
      </div>

      <div className='card-body p-9 '>
        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
            <ProfileDetailsForm />
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
            <LicenseWrapper />
          </div>
        </div>
        <div className='separator my-20 border-0'></div>
      </div>
      <div className='separator my-20 border-0'></div>
      <div className='separator my-20 border-0'></div>
    </div>
  );
};

export {AccountWrapper};
