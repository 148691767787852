import {mockTestData} from './TestData';
import {TrainSetTimeLog} from './TrainSetTimeLog';
import {StatusData, StatusDefinition} from './enums/status';

export const mockTimeLogData: TrainSetTimeLog[] = [
  {
    id: '1',
    reportDate: new Date(1702076085000),
    trainSetCode: 'TS-01',
    trainSetId: '2bVxL50wcN4lWv54c9uE0sxkyIK',
    lastMaintenanceDate: new Date(1701471285000),
    axleTemperatureSum: '36',
    wheelTemperatureSum: '39',
    brakeTemperatureSum: '45',
    status: StatusDefinition.SAFE,
    statusData: StatusData.DONE,
    detail: mockTestData[0],
  },
  {
    id: '2',
    reportDate: new Date(1702076085000),
    trainSetCode: 'TS-02',
    trainSetId: '2bVxL50yCjIGdcg2FkP49CLfi6S',
    lastMaintenanceDate: new Date(1701471285000),
    axleTemperatureSum: '36',
    wheelTemperatureSum: '39',
    brakeTemperatureSum: '45',
    status: StatusDefinition.IN_PROCESS,
    statusData: StatusData.IN_PROCESS,
    detail: mockTestData[1],
  },
  {
    id: '3',
    reportDate: new Date(1702076085000),
    trainSetCode: 'TS-03',
    trainSetId: '2bVxL50zhSa6YxqpT5GzAdA11Ra',
    lastMaintenanceDate: new Date(1701471285000),
    axleTemperatureSum: '36',
    wheelTemperatureSum: '39',
    brakeTemperatureSum: '45',
    status: StatusDefinition.SAFE,
    statusData: StatusData.DONE,
    detail: mockTestData[2],
  },
  {
    id: '4',
    reportDate: new Date(1702076085000),
    trainSetCode: 'TS-04',
    trainSetId: '2bVxL50zhSa6YxqpT5GzAdA11Ra',
    lastMaintenanceDate: new Date(1701471285000),
    axleTemperatureSum: '36',
    wheelTemperatureSum: '39',
    brakeTemperatureSum: '45',
    status: StatusDefinition.EMPTY,
    statusData: StatusData.FAILED,
    detail: mockTestData[3],
  },
  {
    id: '5',
    reportDate: new Date(1702076085000),
    trainSetCode: 'TS-05',
    trainSetId: '2bVxL50zhSa6YxqpT5GzAdA11Ra',
    lastMaintenanceDate: new Date(1701471285000),
    axleTemperatureSum: '36',
    wheelTemperatureSum: '39',
    brakeTemperatureSum: '45',
    status: StatusDefinition.WARNING,
    statusData: StatusData.DONE,
    detail: mockTestData[4],
  },
  {
    id: '6',
    reportDate: new Date(1702076085000),
    trainSetCode: 'TS-06',
    trainSetId: '2bVxL50zhSa6YxqpT5GzAdA11Ra',
    lastMaintenanceDate: new Date(1701471285000),
    axleTemperatureSum: '36',
    wheelTemperatureSum: '39',
    brakeTemperatureSum: '45',
    status: StatusDefinition.DANGER,
    statusData: StatusData.DONE,
    detail: mockTestData[6],
  },
  {
    id: '7',
    reportDate: new Date(1702076085000),
    trainSetCode: 'TS-07',
    trainSetId: '2bVxL50zhSa6YxqpT5GzAdA11Ra',
    lastMaintenanceDate: new Date(1701471285000),
    axleTemperatureSum: '36',
    wheelTemperatureSum: '39',
    brakeTemperatureSum: '45',
    status: StatusDefinition.DANGER,
    statusData: StatusData.DONE,
    detail: mockTestData[0],
  },
  {
    id: '8',
    reportDate: new Date(1702076085000),
    trainSetCode: 'TS1',
    trainSetId: '2bVxL50zhSa6YxqpT5GzAdA11Ra',
    lastMaintenanceDate: new Date(1701471285000),
    axleTemperatureSum: '36',
    wheelTemperatureSum: '39',
    brakeTemperatureSum: '45',
    status: StatusDefinition.DANGER,
    statusData: StatusData.DONE,
    detail: mockTestData[1],
  },
  {
    id: '9',
    reportDate: new Date(1702076085000),
    trainSetCode: 'TS9',
    trainSetId: '2bVxL50zhSa6YxqpT5GzAdA11Ra',
    lastMaintenanceDate: new Date(1701471285000),
    axleTemperatureSum: '36',
    wheelTemperatureSum: '39',
    brakeTemperatureSum: '45',
    status: StatusDefinition.SAFE,
    statusData: StatusData.DONE,
    detail: mockTestData[2],
  },
  {
    id: '10',
    reportDate: new Date(1702076085000),
    trainSetCode: 'TS-01',
    trainSetId: '2bVxL50zhSa6YxqpT5GzAdA11Ra',
    lastMaintenanceDate: new Date(1701471285000),
    axleTemperatureSum: '36',
    wheelTemperatureSum: '39',
    brakeTemperatureSum: '45',
    status: StatusDefinition.SAFE,
    statusData: StatusData.DONE,
    detail: mockTestData[3],
  },
  {
    id: '11',
    reportDate: new Date(1702076085000),
    trainSetCode: 'TS-02',
    trainSetId: '2bVxL50zhSa6YxqpT5GzAdA11Ra',
    lastMaintenanceDate: new Date(1701471285000),
    axleTemperatureSum: '36',
    wheelTemperatureSum: '39',
    brakeTemperatureSum: '45',
    status: StatusDefinition.SAFE,
    statusData: StatusData.DONE,
    detail: mockTestData[4],
  },
  {
    id: '12',
    reportDate: new Date(1702076085000),
    trainSetCode: 'TS-31',
    trainSetId: '2bVxL50zhSa6YxqpT5GzAdA11Ra',
    lastMaintenanceDate: new Date(1701471285000),
    axleTemperatureSum: '36',
    wheelTemperatureSum: '39',
    brakeTemperatureSum: '45',
    status: StatusDefinition.SAFE,
    statusData: StatusData.DONE,
    detail: mockTestData[5],
  },
  {
    id: '13',
    reportDate: new Date(1702076085000),
    trainSetCode: 'TS-04',
    trainSetId: '2bVxL50zhSa6YxqpT5GzAdA11Ra',
    lastMaintenanceDate: new Date(1701471285000),
    axleTemperatureSum: '36',
    wheelTemperatureSum: '39',
    brakeTemperatureSum: '45',
    status: StatusDefinition.SAFE,
    statusData: StatusData.DONE,
    detail: mockTestData[6],
  },
  {
    id: '14',
    reportDate: new Date(1702076085000),
    trainSetCode: 'TS-05',
    trainSetId: '2bVxL50zhSa6YxqpT5GzAdA11Ra',
    lastMaintenanceDate: new Date(1701471285000),
    axleTemperatureSum: '36',
    wheelTemperatureSum: '39',
    brakeTemperatureSum: '45',
    status: StatusDefinition.SAFE,
    statusData: StatusData.DONE,
    detail: mockTestData[0],
  },
  {
    id: '15',
    reportDate: new Date(1702076085000),
    trainSetCode: 'TS-06',
    trainSetId: '2bVxL50zhSa6YxqpT5GzAdA11Ra',
    lastMaintenanceDate: new Date(1701471285000),
    axleTemperatureSum: '36',
    wheelTemperatureSum: '39',
    brakeTemperatureSum: '45',
    status: StatusDefinition.SAFE,
    statusData: StatusData.DONE,
    detail: mockTestData[1],
  },
  {
    id: '16',
    reportDate: new Date(1702076085000),
    trainSetCode: 'TS-07',
    trainSetId: '2bVxL50zhSa6YxqpT5GzAdA11Ra',
    lastMaintenanceDate: new Date(1701471285000),
    axleTemperatureSum: '36',
    wheelTemperatureSum: '39',
    brakeTemperatureSum: '45',
    status: StatusDefinition.SAFE,
    statusData: StatusData.DONE,
    detail: mockTestData[2],
  },
  {
    id: '17',
    reportDate: new Date(1702076085000),
    trainSetCode: 'TS-08',
    trainSetId: '2bVxL50zhSa6YxqpT5GzAdA11Ra',
    lastMaintenanceDate: new Date(1701471285000),
    axleTemperatureSum: '36',
    wheelTemperatureSum: '39',
    brakeTemperatureSum: '45',
    status: StatusDefinition.SAFE,
    statusData: StatusData.DONE,
    detail: mockTestData[3],
  },
];
