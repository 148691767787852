import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers';
import {LOGOUT_MODAL_TARGET} from '../../components/modals/LogoutModal';
import {DashboardUsecase} from '../../../domain/usecases/DashboardUsecase';

interface IProfileDetails {
  avatar: string;
  name: string;
  NIP: string;
  position: string;
}

const initialValues: IProfileDetails = {
  avatar: '/media/avatars/300-1.jpg',
  name: 'Ir. H. Asep Maulana, S.Si,. S.Kom,. M.Kom,. ',
  NIP: '044 3276 454 935',
  position: 'Kondektur',
};

const profileDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Full Name is required'),
  NIP: Yup.string().required('NIP phone is required'),
  position: Yup.string().required('Position site is required'),
});

const ProfileDetailsForm: React.FC = () => {
  const [data, setData] = useState<IProfileDetails>(initialValues);

  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      // setLoading(true);
      setTimeout(() => {
        const updatedData = Object.assign(data, values);
        setData(updatedData);
        // setLoading(false);
      }, 1000);
    },
  });

  useEffect(() => {
    const getProfile = async (): Promise<IProfileDetails> => {
      const user = await DashboardUsecase.getProfile();
      const userModel: IProfileDetails = {
        avatar: user.profile_image_address,
        name: user.full_name,
        position: user.role,
        NIP: ' - ',
      };
      setData(userModel);
      return userModel;
    };

    getProfile().then((profile) => {
      formik.setValues(profile);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <div className='p-9 '>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
            <div className='col-lg-8'>
              <div className='mb-5'>
                <div className='symbol symbol-100px symbol-circle'>
                  <img alt='Pic' src={toAbsoluteUrl(data.avatar)} />
                  <div className='symbol-badge bg-white start-100 top-100 border-4 h-25px w-25px ms-n4 mt-n4 shadow-sm pt-1'>
                    <i className='bi bi-x-lg d-flex flex-center pt-1'></i>
                  </div>
                  <div className='symbol-badge bg-white start-100 top-150 border-4 h-25px w-25px ms-n4 mt-5 shadow-sm ps-1'>
                    <KTSVG
                      path='/media/icons/duotune/art/art005.svg'
                      className='svg-icon-muted svg-icon-1hx d-flex flex-center pt-1 me-1'
                    />
                  </div>
                </div>
              </div>
              <div className='fs-7 text-muted'>Allowed file types: png, jpg, jpeg.</div>
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Nama</label>

            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Full Name'
                disabled={true}
                {...formik.getFieldProps('name')}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.name}</div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>NIP</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                type='tel'
                className='form-control form-control-lg form-control-solid'
                placeholder='NIP'
                disabled={true}
                {...formik.getFieldProps('NIP')}
              />
              {formik.touched.NIP && formik.errors.NIP && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.NIP}</div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Jabatan</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Position'
                disabled={true}
                {...formik.getFieldProps('position')}
              />
              {formik.touched.position && formik.errors.position && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.position}</div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Ubah Password</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <div className='btn btn-dark fw-bold'>Ubah Sekarang</div>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Log Out Akun</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <div
                className='btn btn-danger fw-bold'
                data-bs-toggle='modal'
                data-bs-target={LOGOUT_MODAL_TARGET}
              >
                Log Out
              </div>
            </div>
          </div>
          <div className='separator my-20 border-0'></div>
        </div>

        {/* <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button type='submit' className='btn btn-primary' disabled={loading}>
          {!loading && 'Save Changes'}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div> */}
      </form>
    </div>
  );
};

export {ProfileDetailsForm};
