import {FC} from 'react';
import {Stack} from 'react-bootstrap';
import {randomKeyNumber} from '../../common/Utils';
import {WheelDetail, WheelSideProperty} from '../../data/models/WheelDetail';

type WheelDetailProps = {
  wheelDetail: WheelDetail;
  wheelNumber: number;
};

const _getChipText = (index: number) => {
  const suffix = 'WHEEL';
  const chars = index.toString().split('');
  const lastChar = chars[chars.length - 1];
  var text = `${index}`;
  if (lastChar === '1') {
    text += 'ST';
  } else if (lastChar === '2') {
    text += 'ND';
  } else if (lastChar === '3') {
    text += 'RD';
  } else {
    text += 'TH';
  }
  return `${text} ${suffix}`;
};

const BuildPartition: FC<{type: string; detail: WheelSideProperty}> = ({type, detail}) => {
  const _getTemperatureText = (temperature: number | undefined | null) => {
    return temperature === 0 ? <div className='px-4'>-</div> : detail.left.temperature + '°c';
  };

  const _getTextColor = (isDanger: boolean): string =>
    isDanger ? 'text-danger' : 'text-active-inverse-dark';
  return (
    <>
      <Stack direction='horizontal'>
        <div className={`fs-3 ${_getTextColor(detail.left.isOverHeat)}`}>
          {_getTemperatureText(detail.left.temperature)}
        </div>
        <div className='fs-10 text-gray-600 fw-bold ms-auto'>{type}</div>
        <div className={`fs-3 ${_getTextColor(detail.right.isOverHeat)} ms-auto`}>
          {_getTemperatureText(detail.right.temperature)}
        </div>
      </Stack>
    </>
  );
};

const WheelDetailComponent: FC<WheelDetailProps> = ({wheelDetail, wheelNumber}) => {
  const isDanger = wheelDetail.isContainWarning;
  const borderClassName = `border ${
    isDanger ? 'border-danger' : 'border-gray-300'
  } border rounded max-w-190px min-h-125px py-3 px-4 mx-3 mb-3`;
  const chipClassName = `card ${isDanger ? 'bg-danger' : 'bg-success'} text-inverse-danger px-2`;

  return (
    <>
      <div className='min-h-160px overlay overlay-show'>
        <div className='overlay-layer align-items-start justify-content-center'>
          <div className='d-flex flex-column-fluid flex-center'>
            <div className={chipClassName}>
              <div className='fs-9'>{_getChipText(wheelNumber)}</div>
            </div>
          </div>
        </div>
        <div className='my-2'></div>
        <div className='overlay-wrapper'>
          <div className={borderClassName}>
            <Stack direction='horizontal'>
              <div className='fs-10 text-gray-600 fw-bold'>LEFT</div>
              <div className='fs-10 text-gray-600 fw-bold ms-auto'>RIGHT</div>
            </Stack>
            <div className='my-2'></div>
            <BuildPartition
              key={`axle_${wheelNumber}_${randomKeyNumber}`}
              type='AXLE'
              detail={wheelDetail.axle}
            />
            <div className='my-3'></div>
            <BuildPartition
              key={`wheel_${wheelNumber}_${randomKeyNumber}`}
              type='WHEEL'
              detail={wheelDetail.wheel}
            />
            <div className='my-3'></div>
            <BuildPartition
              key={`brake_${wheelNumber}_${randomKeyNumber}`}
              type='BRAKE'
              detail={wheelDetail.brake}
            />
            <div className='my-1'></div>
          </div>
        </div>
      </div>
    </>
  );
};

export {WheelDetailComponent};
