import {FC} from 'react';
import {DateTimeNowComponent} from '../../components/time-component/DateTimeNow';
import {TimePresentation} from '../../components/time-component/TimePresentation';
import {generateDateTimeText} from '../../../common/Utils';

type TimeHeaderProps = {
  lastUpdate: Date;
};

const TimeHeader: FC<TimeHeaderProps> = ({lastUpdate}) => {
  return (
    <>
      <div className='row'>
        <div className='col'>
          <DateTimeNowComponent />
          <i>
            <TimePresentation timeText={generateDateTimeText(lastUpdate)} prefix='Last update: ' />
          </i>
        </div>
      </div>
    </>
  );
};

export {TimeHeader};
