import {FC, useState} from 'react';
import {Stack} from 'react-bootstrap';
import {SettingConstant} from '../SettingConstant';
import {TrainsetAlertPartitionForm} from './TrainsetAlertPartitionForm';
import {ITrainsetSetting} from '../../../../data/models/settings/TrainsetSetting';
import {IHotboxSetting} from '../../../../data/models/settings/HotboxSetting';
import {SubmitState} from './TabSettingCard';
import { SubmitButton } from '../../../components/button/SubmitButton';

type TrainsetAlertFormType = {
  trainsetId: string;
  setting?: ITrainsetSetting;
  onSubmit: (partitionSetting: IHotboxSetting) => void;
  isLoading: boolean;
  submitState: SubmitState;
};

const TrainsetAlertForm: FC<TrainsetAlertFormType> = ({
  trainsetId,
  setting,
  onSubmit,
  isLoading,
  submitState,
}) => {
  const [axleSetup, setAxleSetup] = useState({
    ...setting?.hotboxSetting?.axle,
  });
  const [breakSetup, setBreakSetup] = useState({...setting?.hotboxSetting?.break});
  const [wheelSetup, setWheelSetup] = useState({...setting?.hotboxSetting?.wheel});

  const onSubmitCallback = () => {
    onSubmit({
      axle: {
        bottomSafe: axleSetup.bottomSafe!,
        topSafe: axleSetup.topSafe!,
        topWarning: axleSetup.topWarning!,
      },
      break: {
        bottomSafe: breakSetup.bottomSafe!,
        topSafe: breakSetup.topSafe!,
        topWarning: breakSetup.topWarning!,
      },
      wheel: {
        bottomSafe: wheelSetup.bottomSafe!,
        topSafe: wheelSetup.topSafe!,
        topWarning: wheelSetup.topWarning!,
      },
    });
  };
  return (
    <>
      <div>
        <div className='my-10'>
          <div className='hstack'>
            <div className='fs-3 w-200px text-gray-700 fw-bold'>Nomor Trainset</div>
            <div className='card bg-gray-200 p-4 min-w-125px border-0'>
              <div className='fs-7 text-gray-600 fw-bold'>{trainsetId}</div>
            </div>
          </div>
        </div>
        <TrainsetAlertPartitionForm
          title={SettingConstant.partTitleAxle}
          partitionSetting={setting?.hotboxSetting?.axle}
          onChange={(axleSetting) => {
            setAxleSetup(axleSetting);
          }}
        />
        <TrainsetAlertPartitionForm
          title={SettingConstant.partTitleWheel}
          partitionSetting={setting?.hotboxSetting?.wheel}
          onChange={(wheelSetting) => {
            setWheelSetup(wheelSetting);
          }}
        />
        <TrainsetAlertPartitionForm
          title={SettingConstant.partTitleBreak}
          partitionSetting={setting?.hotboxSetting?.break}
          onChange={(breakSetting) => {
            setBreakSetup(breakSetting);
          }}
        />
        <div className='mt-7 mb-10'>
          <Stack direction='horizontal'>
            <div className="ms-auto">
              <SubmitButton isLoading={isLoading} state={submitState} onClick={onSubmitCallback}/>
            </div>
          </Stack>
        </div>
      </div>
    </>
  );
};
export {TrainsetAlertForm};
