import axios from 'axios';
import {AuthResponse, UserModel} from './_models';
import {DefaultResponse} from '../../../../data/models/response/DefaultResponse';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/profile`;
export const LOGIN_URL = `${API_URL}/login`;
export const LOGOUT_URL = `${API_URL}/logout`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;

// Server should return AuthModel
export async function login(username: string, password: string): Promise<AuthResponse> {
  const result = await axios.post<DefaultResponse<AuthResponse[]>>(LOGIN_URL, {
    account: username,
    password,
  });
  return result.data.data[0];
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export async function getUserByToken(): Promise<UserModel> {
  const response = await axios.get<DefaultResponse<UserModel[]>>(GET_USER_BY_ACCESSTOKEN_URL);
  return response.data.data[0];
}

export async function logoutToken() {
  await axios.get(LOGOUT_URL);
}
