import clsx from 'clsx';
import {FC} from 'react';

export interface IDropdownProp {
  title: string;
  action: (prop: IDropdownProp) => void;
}

type DropdownProp = {
  props: IDropdownProp[];
  activeProp: IDropdownProp;
};

const DropdownItem: FC<{prop: IDropdownProp; isActive: boolean}> = ({prop, isActive}) => {
  return (
    <>
      <div className='menu-item px-3 my-0'>
        <div
          className={clsx('menu-link px-3 py-2', {active: isActive})}
          onClick={() => prop.action(prop)}
        >
          <span className='menu-title fw-bold'>{prop.title}</span>
        </div>
      </div>
    </>
  );
};

const CustomDropdown: FC<DropdownProp> = ({props, activeProp}) => {
  const dropdownContent: JSX.Element[] = [];
  props.forEach((prop) => {
    dropdownContent.push(
      <DropdownItem key={prop.title} prop={prop} isActive={activeProp.title === prop.title} />
    );
  });
  return (
    <>
      <a
        href='/'
        className='btn btn-sm btn-bg-light text-muted w-200px h-45px py-4 btn-active-icon-primary'
        data-kt-menu-trigger={"{default: 'click', lg: 'hover'}"}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={'bottom-end'}
        aria-disabled={true}
      >
        <div className='hstack'>
          <div className='fs-5 fw-bold'>{activeProp.title}</div>
          <div className='badge badge-secondary ms-auto'>
            <i className='bi bi-funnel-fill fs-4 text-dark '></i>
          </div>
        </div>
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-200px'
        data-kt-menu='true'
      >
        {dropdownContent}
      </div>
    </>
  );
};

export {CustomDropdown};
