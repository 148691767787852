import {WheelDetailItemResponse, WheelResponse} from './response/TrainsetResponse';

export interface PartitionDetail {
  temperature: number;
  isOverHeat: boolean;
}
export interface WheelSideProperty {
  left: PartitionDetail;
  right: PartitionDetail;
}
export interface WheelDetail {
  isContainWarning: boolean;
  axle: WheelSideProperty;
  wheel: WheelSideProperty;
  brake: WheelSideProperty;
}

function toPartitionDetail(response: WheelDetailItemResponse): PartitionDetail {
  return {
    temperature: response.data_value_decimal,
    isOverHeat: response.is_item_alarmed,
  };
}

function toWheelSideProperty(response: WheelDetailItemResponse): Partial<WheelSideProperty> {
  if (response.item_position === 'LEFT') {
    return {left: toPartitionDetail(response)};
  }
  return {right: toPartitionDetail(response)};
}

export function toWheelDetailFromResponse(response: WheelResponse): WheelDetail {
  const wheelDetailPartial = Object();
  for (let detailResponse of response.train_set_temperature_wheel_item) {
    if (detailResponse.item_name === 'AXLE') {
      wheelDetailPartial.axle = {
        ...wheelDetailPartial.axle, 
        ...toWheelSideProperty(detailResponse)
      }
    }
    if (detailResponse.item_name === 'WHEEL') {
      wheelDetailPartial.wheel = {
        ...wheelDetailPartial.wheel, 
        ...toWheelSideProperty(detailResponse)
      }
    }
    if (detailResponse.item_name === 'BRAKE') {
      wheelDetailPartial.brake = {
        ...wheelDetailPartial.brake, 
        ...toWheelSideProperty(detailResponse)
      }
    }
  }
  return {
    isContainWarning: response.is_wheel_alarmed,
    ...wheelDetailPartial,
  };
}
