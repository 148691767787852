import {FC, lazy, Suspense} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {MasterLayout} from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper';
import {MenuTestPage} from '../pages/MenuTestPage';
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils';
import {WithChildren} from '../../_metronic/helpers';
import {hotBoxRoutes} from '../../common/Routes';
import {LogHistoryWrapper} from '../pages/log-history/LogHistoryWrapper';
import {SettingsWrapper} from '../pages/settings/SettingsWrapper';
import {TrainsetSettingWrapper} from '../pages/settings/trainset-setting/TrainsetSettingWrapper';
import {TrainsetLogDetailWrapper} from '../pages/dashboard/TrainsetLogDetail/TrainsetLogDetailWrapper';
import {NotificationWrapper} from '../pages/notification/NotificationWrapper';
import {UserProfileWrapper} from '../pages/user-profile/UserProfileWrapper';

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'));
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'));
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path={hotBoxRoutes.dashboardRoute.path} element={<DashboardWrapper />} />
        <Route path={hotBoxRoutes.logHistoryRoute.path} element={<LogHistoryWrapper />} />
        <Route path={hotBoxRoutes.settingsRoute.path} element={<SettingsWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path={hotBoxRoutes.trainsetSettingRoute.path} element={<TrainsetSettingWrapper />} />
        <Route
          path={hotBoxRoutes.trainsetLogDetailRoute.path}
          element={<TrainsetLogDetailWrapper />}
        />
        <Route path={hotBoxRoutes.notificationRoute.path} element={<NotificationWrapper />} />
        <Route path={hotBoxRoutes.profileSettingRoute.path} element={<UserProfileWrapper />} />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export {PrivateRoutes};
