import {FC} from 'react';

type Props = {
  title: string;
  subtitle?: string;
};

const PageHeader: FC<Props> = ({title, subtitle}) => {
  return (
    <>
      <div className='page-title me-5'>
        <h1 className='d-flex flex-column fs-1 mb-0 text-dark'>
          <div className='fw-bolder'>{title}</div>
          {subtitle && <small className='text-muted fs-7 fw-semibolder my-1'>{subtitle}</small>}
        </h1>
      </div>
    </>
  );
};

export {PageHeader};
