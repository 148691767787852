export interface HotboxAppLicense{
    dashboardVersion: String;
    lastUpdate: Date;
    activeDate: Date;
    documentUrl: String;
}

export const dummyHotboxAppLicenseData : HotboxAppLicense = {
    dashboardVersion: '1.0.0',
    activeDate: new Date(new Date(Date.now()).setFullYear((new Date()).getFullYear() + 1)),
    lastUpdate: new Date(new Date(Date.now()).setDate((new Date()).getDate() - 1)),
    documentUrl: 'https://www.google.com',
}