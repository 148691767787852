interface IRoute {
  path: string;
  menuName: string;
}

const dashboardRoute: IRoute = {path: '/dashboard', menuName: 'Dashboard'};
const logHistoryRoute: IRoute = {path: '/log-history', menuName: 'Log History'};
const settingsRoute: IRoute = {path: '/settings', menuName: 'Pengaturan'};
const trainsetSettingRoute: IRoute = {path: '/settings/trainset-setting', menuName: 'Pengaturan'};
const trainsetLogDetailRoute: IRoute = {path: '/log-history/detail', menuName: 'Log Detail'};
const notificationRoute: IRoute = {path: '/notification', menuName: 'notification'};
const profileSettingRoute: IRoute = {path: '/user-profile', menuName: 'Profile'};

export const hotBoxRoutes = {
  dashboardRoute,
  logHistoryRoute,
  settingsRoute,
  trainsetSettingRoute,
  trainsetLogDetailRoute,
  notificationRoute,
  profileSettingRoute,
};

export const menus = [dashboardRoute, logHistoryRoute, settingsRoute];
