import {INotification} from '../models/notification/Notification';
import {notificationTestData} from '../models/notification/NotificationTestData';

const notificationDataStore = [...notificationTestData];

const getAll = (): INotification[] => {
  return notificationDataStore;
};

const createNotification = () => {
  const lastData = notificationDataStore[notificationDataStore.length - 1];
  const notification: INotification = {
    id: `${Number(lastData.id) + 1}`,
    dataId: '14',
    captureDate: new Date(Date.now()),
    isRead: false,
    trainsetName: lastData.trainsetName === 'TS 2' ? 'TS 1' : 'TS 2',
  };
  console.log('======>>> create notification with id: ', notification.id);
  notificationDataStore.push(notification);
};

const updateRead = (id: string) => {
  const notification = notificationDataStore.find((el) => el.id === id);
  if (!notification) {
    console.log('not found notification with id', id);
  }
  const index = notificationDataStore.findIndex((el) => el.id === id);
  notificationDataStore[index] = {...notification!, isRead: true};
};

export const NotificationRepository = {
  getAll,
  createNotification,
  updateRead,
};
