import axios from 'axios';
import {mockTestData} from '../models/TestData';
import {TrainSet} from '../models/TrainSet';
import { DefaultResponse } from '../models/response/DefaultResponse';
import { TrainsetResponse } from '../models/response/TrainsetResponse';
import { ALL_TEMPERATURE_URL } from './UrlConstant';

const getInitialData = (): TrainSet => {
  return mockTestData[0];
};

const getAll = async (): Promise<TrainsetResponse[]> => {
  const response = await axios.get<DefaultResponse<TrainsetResponse[]>>(ALL_TEMPERATURE_URL);
  return response.data.data;
};

export const TrainSetDashboardRepository = {
  getInitialData,
  getAll,
};
