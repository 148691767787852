import {FC} from 'react';
import {AssetsConstant} from '../../common/AssetsConstants';
import {toAbsoluteUrl} from '../../_metronic/helpers';

export enum InvalidDataType {
  NO_DATA,
  DATA_STILL_PROCESSING,
  DATA_NOT_READY_YET,
  FAILED,
}
type Props = {
  type: InvalidDataType;
  callbackRefresh?: Function;
};

const InvalidDataComponent: FC<Props> = ({type, callbackRefresh}) => {
  const _getText = (): string => {
    switch (type) {
      case InvalidDataType.NO_DATA:
        return 'Tidak ada data';
      case InvalidDataType.DATA_NOT_READY_YET:
        return 'Data belum tersedia';
      case InvalidDataType.FAILED:
        return 'Gagal mengambil data';
      default:
        return 'Data sedang dikumpulkan...';
    }
  };

  const _getIcon = (): string => {
    switch (type) {
      case InvalidDataType.NO_DATA:
        return AssetsConstant.warning;
      case InvalidDataType.FAILED:
        return AssetsConstant.error;
      default:
        return AssetsConstant.onProcess;
    }
  };
  const _isShowRetryButton = type === InvalidDataType.FAILED;

  return (
    <>
      <div key={'invalid_data_component'} className='row'>
        <div className='text-center'>
          <div className='symbol symbol-100px symbol-1by3 mb-0'>
            <img src={toAbsoluteUrl(_getIcon())} alt='' />
          </div>
        </div>
        <div className='text-center py-5'>
          <div className='fs-4'>{_getText()}</div>
        </div>

        {_isShowRetryButton && (
          <div className='text-center'>
            <div
              className='btn btn-primary py-2 fw-bold'
              onClick={() => {
                if (callbackRefresh) {
                  callbackRefresh();
                }
              }}
            >
              Perbarui
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export {InvalidDataComponent};
