import {FC, useEffect, useState} from 'react';
import {NotificationConstant} from './NotificationConstant';
import {NotificationTile} from './NotificationTile';
import {INotification} from '../../../data/models/notification/Notification';
import {NotificationUsecase} from '../../../domain/usecases/NotificationUsecase';
import {InvalidDataComponent, InvalidDataType} from '../../components/InvalidDataComponent';

const NotificationTabCard: FC = () => {
  const [todayNotifications, setTodayNotifications] = useState<INotification[]>([]);
  const [olderNotifications, setOlderNotifications] = useState<INotification[]>([]);

  useEffect(() => {
    const now = new Date(Date.now());
    const data = NotificationUsecase.getAll();
    const today: INotification[] = [];
    const older: INotification[] = [];
    data.forEach((notification) => {
      if (
        notification.captureDate.getDate() === now.getDate() &&
        notification.captureDate.getMonth() === now.getMonth()
      ) {
        today.push(notification);
      } else {
        older.push(notification);
      }
    });
    setTodayNotifications(
      today.sort((a, b) => (b.captureDate.getTime() > a.captureDate.getTime() ? 1 : -1))
    );
    setOlderNotifications(
      older.sort((a, b) => (b.captureDate.getTime() > a.captureDate.getTime() ? 1 : -1))
    );
  }, []);

  return (
    <>
      <div className='card'>
        <div className='card-header card-header-stretch h-50px'>
          <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-3x nav-stretch mb-0 fs-6'>
            <li className='nav-item'>
              <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
                {NotificationConstant.tabTitleNotificationLatest}
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                {NotificationConstant.tabTitleNotificationOld}
              </a>
            </li>
          </ul>
        </div>
        <div className='card-body py-5'>
          <div className='tab-content' id='myTabContent'>
            <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
              {todayNotifications.length === 0 && (
                <>
                  <div className='my-20'>
                    <InvalidDataComponent type={InvalidDataType.NO_DATA} />
                  </div>
                </>
              )}
              {todayNotifications.map((notification) => (
                <NotificationTile key={notification.id} notification={notification} />
              ))}
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
              {olderNotifications.length === 0 && (
                <>
                  <div className='my-20'>
                    <InvalidDataComponent type={InvalidDataType.NO_DATA} />
                  </div>
                </>
              )}
              {olderNotifications.map((notification) => (
                <NotificationTile key={notification.id} notification={notification} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export {NotificationTabCard};
