import {FC} from 'react';

const TimePresentation: FC<{timeText: string; prefix?: string; className?: string}> = ({
  timeText,
  prefix,
  className,
}) => {
  return (
    <>
      <div className={`${className ?? 'fs-3 text-gray-500'}`}>
        {prefix}
        {timeText}
      </div>
    </>
  );
};

export {TimePresentation};
