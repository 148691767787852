import {FC, useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {TrainsetAlertForm} from './TrainsetAlertForm';
import {RFIDForm} from './RFIDForm';
import {SettingConstant} from '../SettingConstant';
import {ITrainsetSetting} from '../../../../data/models/settings/TrainsetSetting';
import {SettingUsecase} from '../../../../domain/usecases/SettingUsecase';

export enum SubmitState {
  success,
  failed,
  idle,
}

const TabSettingCard: FC = () => {
  const paramId = new URLSearchParams(useLocation().search).get('id');
  const id = paramId ?? 'TS 0';
  const [setting, setSetting] = useState<ITrainsetSetting | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitState, setSubmitState] = useState<SubmitState>(SubmitState.idle);

  useEffect(() => {
    const result = SettingUsecase.getByTrainsetCode(id);
    setSetting(result);
  }, [id]);

  const _upsert = useCallback(
    (newSetting: Partial<ITrainsetSetting>) => {
      setIsLoading(true);
      const payload: ITrainsetSetting = {
        code: setting?.code ?? id,
        name: setting?.name,
        hotboxSetting: newSetting?.hotboxSetting ?? setting?.hotboxSetting,
        rFIDSettings: newSetting?.rFIDSettings ?? setting?.rFIDSettings ?? [],
      };
      SettingUsecase.upsert(payload);
      setSetting(payload);
      setTimeout(() => {
        setIsLoading(false);
        setSubmitState(SubmitState.success);
        setTimeout(() => {
          setSubmitState(SubmitState.idle);
        }, 2000);
      }, 2000);
    },
    [id, setting?.code, setting?.hotboxSetting, setting?.name, setting?.rFIDSettings]
  );

  return (
    <>
      <div className='card'>
        <div className='card-body py-5'>
          <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-3x mb-5 fs-6'>
            <li className='nav-item'>
              <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
                {SettingConstant.tabTitleTrainsetData}
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                {SettingConstant.tabTilteRFIDData}
              </a>
            </li>
          </ul>
          <div className='tab-content' id='myTabContent'>
            <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
              <TrainsetAlertForm
                trainsetId={id}
                setting={setting}
                onSubmit={(partitionSetting) => {
                  _upsert({hotboxSetting: partitionSetting});
                }}
                isLoading={isLoading}
                submitState={submitState}
              />
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
              <RFIDForm
                settings={setting?.rFIDSettings}
                onSubmit={(rFIDSettings) => {
                  _upsert({rFIDSettings: rFIDSettings});
                }}
                isLoading={isLoading}
                submitState={submitState}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export {TabSettingCard};
