import {TrainSet, toTrainsetFromResponse} from '../../data/models/TrainSet';
import {TrainSetDashboardRepository} from '../../data/repositories/TrainSetDashboardRepository';

const getInitialData = (): TrainSet => {
  return TrainSetDashboardRepository.getInitialData();
};

const getAll = async (): Promise<TrainSet[]> => {
  const trainsets: TrainSet[] = [];
  const responses = await TrainSetDashboardRepository.getAll();
  for (let response of responses) {
    trainsets.push(toTrainsetFromResponse(response));
  }
  return trainsets;
};

export const TrainSetDashboardUsecase = {
  getInitialData,
  getAll,
};
