import {useEffect, useState} from 'react';
import {TrainSetTimeLog} from '../../../data/models/TrainSetTimeLog';
import {LogTable} from './LogTable';
import {LogTableHeader} from './LogTableHeader';
import {TrainSetTimeLogUsecase} from '../../../domain/usecases/TrainSetTimeLogUsecase';
import {State, StateStatus} from '../../../common/State';
import {StateBuilder} from '../../../common/StateBuilder';
import {LogTableShimmer} from './LogTableShimmer';
import {PageHeader} from '../../components/PageHeader';

const LogHistoryWrapper = () => {
  const loadingState: State<TrainSetTimeLog[]> = {status: StateStatus.LOADING, data: []};
  const [state, setState] = useState<State<TrainSetTimeLog[]>>(loadingState);

  useEffect(() => {
    const result = TrainSetTimeLogUsecase.getAll();
    setState({status: StateStatus.LOADED, data: result});
  }, [setState]);
  return (
    <>
      <div className='container bg-gray-300 m-0 pt-6'>
        <PageHeader title='Time Log' subtitle='Home - Dashboard' />
        <div className='mb-15'></div>

        <LogTableHeader />
        <div className='mb-8'></div>
        <StateBuilder
          state={state}
          loadingBuilder={() => <LogTableShimmer />}
          loadedBuilder={() => <LogTable logs={state.data!} />}
          errorBuilder={() => <h1>Loading</h1>}
        />
        <div className='mb-20'></div>
      </div>
    </>
  );
};

export {LogHistoryWrapper};
