/* eslint-disable jsx-a11y/anchor-is-valid */
import {useLayout} from '../../core';
import {KTIcon, checkIsActive, toAbsoluteUrl} from '../../../helpers';
import {Link, useLocation} from 'react-router-dom';
import {hotBoxRoutes, menus} from '../../../../common/Routes';
import {AssetsConstant} from '../../../../common/AssetsConstants';
import {
  LOGOUT_MODAL_TARGET,
} from '../../../../presentation/components/modals/LogoutModal';

const HeaderToolbar = () => {
  const {classes} = useLayout();

  const _buildMenus = () => {
    const links = [];
    const {pathname} = useLocation();

    for (const route of menus) {
      const isActive = checkIsActive(pathname, route.path);
      links.push(
        <Link
          key={route.menuName}
          className={`btn ${
            isActive ? 'btn-bg-custom-gray text-white' : 'btn-color-gray-400'
          } fw-bold py-2 me-3`}
          to={route.path}
        >
          {route.menuName}
        </Link>
      );
    }
    return links;
  };

  return (
    <div className='toolbar d-flex align-items-center bg-dark'>
      {/* begin::Toolbar container */}
      <div
        className={`${classes.headerContainer.join(
          ' '
        )} py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between`}
      >
        {/* <DefaultTitle /> */}

        <div>
          <Link to={menus[0].path}>
            <div className='symbol symbol-45px symbol-1by3 mb-0 me-15'>
              <img src={toAbsoluteUrl(AssetsConstant.imamLogo)} alt='' />
            </div>
          </Link>
          {_buildMenus()}
        </div>

        <div className='d-flex align-items-stretch overflow-auto pt-3 pt-lg-0'>
          {/* begin::Action wrapper */}
          <div className='d-flex align-items-center'>
            {/* begin::Actions */}
            <div className='d-flex'>
              {/* begin::Notifications */}
              <div className='d-flex align-items-center'>
                {/* begin::Menu- wrapper */}
                <Link to={{pathname: hotBoxRoutes.notificationRoute.path}}>
                  <div className='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary'>
                    <i className='bi bi-bell-fill fs-1 text-white'></i>
                  </div>
                </Link>
                {/* end::Menu wrapper */}
              </div>
              {/* end::Notifications */}

              {/* begin::Quick links */}
              <div className='d-flex align-items-center'>
                {/* begin::Menu wrapper */}
                <Link to={{pathname: hotBoxRoutes.profileSettingRoute.path}}>
                  <div className='symbol symbol-40px mx-5'>
                    <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='' />
                  </div>
                </Link>
                {/* end::Menu wrapper */}
              </div>
              {/* end::Quick links */}

              {/* begin::Theme mode */}
              <div className='d-flex align-items-center'>
                <a
                  className='btn btn-sm btn-icon btn-icon-muted btn-active-icon-light'
                  data-bs-toggle='modal'
                  data-bs-target={LOGOUT_MODAL_TARGET}
                >
                  <KTIcon iconName='entrance-left' className='fs-1 minimize-active' />
                </a>
              </div>

              {/* end::Theme mode */}
            </div>
            {/* end::Actions */}
          </div>
          {/* end::Action wrapper */}
        </div>
        {/* end::Toolbar container */}
      </div>
    </div>
  );
};

export {HeaderToolbar};
