import {FC, useEffect, useRef, useState} from 'react';
import {TimerIndicator} from '../../../components/time-component/TimerIndicator';
import {DEFAULT_TIMER} from '../../../../common/CommonConstants';
import {getNextFocusedIndex} from '../../../../common/Utils';
import {TrainSetListDashboardItem} from '../../../../data/models/Dashboard';
import {NotificationUsecase} from '../../../../domain/usecases/NotificationUsecase';

type TrainSetDashboardHeaderProps = {
  trainSets: TrainSetListDashboardItem[];
  onRestartTimer: (nextIndex: number) => void;
  onTapCard: (nextTrainSet: TrainSetListDashboardItem) => void;};

const TrainSetDashboardHeader: FC<TrainSetDashboardHeaderProps> = ({
  trainSets,
  onRestartTimer,
  onTapCard,
}) => {
  const [seconds, setSeconds] = useState(DEFAULT_TIMER);
  const [isPaused, setIsPaused] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const shownIndexStart = useRef(0);
  const nextShownIndex = useRef(10);
  let tSDestination: string[] = [];

  const scroll = (nextIndex?: number) => {
    const id = tSDestination[nextIndex ?? shownIndexStart.current];
    document.getElementById(id)?.scrollIntoView({
      behavior: 'smooth',
      inline: 'start',
      block: 'nearest',
    });
  };

  const _resetTimer = (nextIndex: number, isNeedScroll: boolean, needRefetch: boolean) => {
    setFocusedIndex(nextIndex);
    if(needRefetch) onRestartTimer(nextIndex);
    setSeconds(DEFAULT_TIMER);
    if (shownIndexStart.current < tSDestination.length - 1) {
      shownIndexStart.current = shownIndexStart.current + 1;
    } else {
      shownIndexStart.current = 0;
    }
    nextShownIndex.current = shownIndexStart.current;
    if (isNeedScroll) scroll();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        if (seconds > 0) {
          setSeconds(seconds - 1);
          // TODO: delete later, only dummy creation notification
          if (seconds % 10 === 0) {
            NotificationUsecase.createNotification();
          }
        } else {
          const nextIndex = getNextFocusedIndex(focusedIndex, trainSets.length);
          _resetTimer(nextIndex, true, true);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds, isPaused, onRestartTimer, setFocusedIndex, focusedIndex]);

  const _handleOnTapIndicator = (index: number) => {
    _resetTimer(index, false, false);
    onTapCard(trainSets[index]);
    shownIndexStart.current = index;
  };

  const trainSetOrder = [];

  for (let i = 0; i < trainSets.length; i++) {
    tSDestination.push(`TS${i}`);
    trainSetOrder.push(
      <TrainSetActiveIndicator
        key={`TS${i}`}
        index={i}
        indexNow={focusedIndex}
        text={trainSets[i].trainSetCode}
        onClick={() => _handleOnTapIndicator(i)}
      />
    );
  }

  return (
    <>
      <div>
        <div className='d-flex flex-row justify-content-xl-between align-items-center'>
          <div
            className='btn btn-icon btn-sm me-2 w-10px'
            onClick={() => {
              let nextIndex = nextShownIndex.current - 10;
              if (nextIndex < 0) {
                nextIndex = 0;
              }
              nextShownIndex.current = nextIndex;
              scroll(nextIndex);
            }}
          >
            <i className={`bi bi-chevron-left fs-1 text-black my-3 `}></i>
          </div>
          <div
            className='flex-grow-1 h-35px'
            style={{overflowY: 'scroll', scrollBehavior: 'smooth'}}
          >
            <div className='d-flex flex-row align-items-center'>{trainSetOrder}</div>
          </div>
          <div
            className='btn btn-icon btn-sm ms-2 w-10px'
            onClick={() => {
              let nextIndex = nextShownIndex.current + 10;
              if (nextIndex > tSDestination.length - 1) {
                nextIndex = tSDestination.length - 1;
              }
              nextShownIndex.current = nextIndex;
              scroll(nextIndex);
            }}
          >
            <i className={`bi bi-chevron-right fs-1 text-black`}></i>
          </div>
        </div>

        <div className='row align-items-center justify-content-between mt-3'>
          {trainSets[focusedIndex].trainSet && (
            <div className='col'>
              <h1 className='fs-1'>Kode Trainset {trainSets[focusedIndex].trainSetCode}</h1>
            </div>
          )}
          <div className='w-85px ms-auto'>
            <TimerIndicator
              seconds={seconds}
              isRun={!isPaused}
              onTap={() => {
                setIsPaused(!isPaused);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const TrainSetActiveIndicator: FC<{
  index: number;
  indexNow: number;
  onClick: () => void;
  text: string;
}> = ({index, indexNow, onClick, text}) => {
  const conditionalStyle = `btn ${index === indexNow ? 'btn-primary' : 'bg-white'}`;
  return (
    <>
      <div
        id={`TS${index}`}
        className={`${conditionalStyle} border border-1 border-black fw-bold h-30px min-w-65px pt-1 px-2 mx-1`}
        onClick={() => onClick()}
      >
        {text}
      </div>
    </>
  );
};

export {TrainSetDashboardHeader};
