import {FC, useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {TrainSetTimeLogUsecase} from '../../../../domain/usecases/TrainSetTimeLogUsecase';
import {InvalidDataComponent, InvalidDataType} from '../../../components/InvalidDataComponent';
import {DashboardHeader} from '../DashboardHeader';
import {TrainSetDashboard} from '../TrainSetDashboard/TrainSetDashboard';
import {TrainSet} from '../../../../data/models/TrainSet';
import {TrainSetListDashboardItem} from '../../../../data/models/Dashboard';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {PDF_SETUP} from '../../../../common/CommonConstants';

const TrainsetLogDetailWrapper: FC = () => {
  const paramId = new URLSearchParams(useLocation().search).get('id');
  const [data, setData] = useState<TrainSet | undefined>();
  const [isCapturePDF, setIsCapturePDF] = useState<boolean>(false);

  const _generatePDF = async () => {
    setIsCapturePDF(true);
    setTimeout(async () => {
      await html2canvas(document.querySelector('#capture-log-detail')!)
        .then((canvas: any) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF(PDF_SETUP);
          pdf.addImage(imgData, 'PNG', 0, 0, 0, 0, 'log', 'NONE', 0);
          pdf.save('log.pdf');
        })
        .catch((e: any) => {
          console.log('error', e);
        });
      setIsCapturePDF(false);
    }, 1000);
  };

  const fetch = useCallback(async () => {
    const result = await TrainSetTimeLogUsecase.getById(paramId!);
    setData(result);
  }, [paramId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const _buildDashboardData = (trainset: TrainSet): TrainSetListDashboardItem => {
    return {
      trainSetCode: trainset.code,
      trainSet: trainset,
      isError: false,
    };
  };

  if (data) {
    return (
      <>
        <div id='capture-log-detail'>
          <DashboardHeader
            key={'header'}
            lastUpdate={data.lastUpdate}
            isForDetailPage={true}
            pdfCallback={_generatePDF}
            isCapturePDF={isCapturePDF}
          />
          <TrainSetDashboard
            key={'dashboard'}
            data={[_buildDashboardData(data)]}
            isForDetailPage={true}
          />
        </div>
      </>
    );
  }
  return (
    <div key={'failed'} className='content h-500px pt-20'>
      <InvalidDataComponent key={'failed_component'} type={InvalidDataType.FAILED} />
    </div>
  );
};

export {TrainsetLogDetailWrapper};
