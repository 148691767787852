import {FC} from 'react';
import ContentLoader from 'react-content-loader';

const LogTableShimmer: FC = (props) => (
  <div className='card p-0 m-0'>
    <div className="card-body p-0 m-0">
    <ContentLoader
      className='container'
      viewBox='0 0 1500 630'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
      {...props}
    >
      <rect x='0' y='0' rx='10' ry='10' width='1500' height='75' />

      <rect x='0' y='100' rx='10' ry='10' width='160' height='19' />
      <rect x='0' y='157' rx='10' ry='10' width='160' height='19' />
      <rect x='0' y='219' rx='10' ry='10' width='160' height='19' />
      <rect x='0' y='277' rx='10' ry='10' width='160' height='19' />
      <rect x='0' y='340' rx='10' ry='10' width='160' height='19' />
      <rect x='0' y='400' rx='10' ry='10' width='160' height='19' />
      <rect x='0' y='460' rx='10' ry='10' width='160' height='19' />
      <rect x='0' y='520' rx='10' ry='10' width='160' height='19' />
      <rect x='0' y='580' rx='10' ry='10' width='160' height='19' />

      <rect x='188' y='100' rx='10' ry='10' width='169' height='19' />
      <rect x='187' y='158' rx='10' ry='10' width='169' height='19' />
      <rect x='187' y='220' rx='10' ry='10' width='169' height='19' />
      <rect x='187' y='278' rx='10' ry='10' width='169' height='19' />
      <rect x='187' y='341' rx='10' ry='10' width='169' height='19' />
      <rect x='187' y='400' rx='10' ry='10' width='169' height='19' />
      <rect x='187' y='460' rx='10' ry='10' width='169' height='19' />
      <rect x='187' y='520' rx='10' ry='10' width='169' height='19' />
      <rect x='187' y='580' rx='10' ry='10' width='169' height='19' />


      <rect x='402' y='100' rx='10' ry='10' width='85' height='19' />
      <rect x='401' y='157' rx='10' ry='10' width='85' height='19' />
      <rect x='401' y='219' rx='10' ry='10' width='85' height='19' />
      <rect x='401' y='277' rx='10' ry='10' width='85' height='19' />
      <rect x='401' y='340' rx='10' ry='10' width='85' height='19' />
      <rect x='401' y='400' rx='10' ry='10' width='85' height='19' />
      <rect x='401' y='460' rx='10' ry='10' width='85' height='19' />
      <rect x='401' y='520' rx='10' ry='10' width='85' height='19' />
      <rect x='401' y='580' rx='10' ry='10' width='85' height='19' />


      <rect x='523' y='100' rx='10' ry='10' width='169' height='19' />
      <rect x='522' y='158' rx='10' ry='10' width='169' height='19' />
      <rect x='522' y='220' rx='10' ry='10' width='169' height='19' />
      <rect x='522' y='278' rx='10' ry='10' width='169' height='19' />
      <rect x='522' y='341' rx='10' ry='10' width='169' height='19' />
      <rect x='522' y='401' rx='10' ry='10' width='169' height='19' />
      <rect x='522' y='461' rx='10' ry='10' width='169' height='19' />
      <rect x='522' y='521' rx='10' ry='10' width='169' height='19' />
      <rect x='522' y='581' rx='10' ry='10' width='169' height='19' />


      <rect x='731' y='99' rx='10' ry='10' width='85' height='19' />
      <rect x='730' y='156' rx='10' ry='10' width='85' height='19' />
      <rect x='730' y='218' rx='10' ry='10' width='85' height='19' />
      <rect x='730' y='276' rx='10' ry='10' width='85' height='19' />
      <rect x='730' y='339' rx='10' ry='10' width='85' height='19' />
      <rect x='730' y='400' rx='10' ry='10' width='85' height='19' />
      <rect x='730' y='460' rx='10' ry='10' width='85' height='19' />
      <rect x='730' y='520' rx='10' ry='10' width='85' height='19' />
      <rect x='730' y='580' rx='10' ry='10' width='85' height='19' />


      <rect x='852' y='98' rx='10' ry='10' width='85' height='19' />
      <rect x='851' y='155' rx='10' ry='10' width='85' height='19' />
      <rect x='851' y='217' rx='10' ry='10' width='85' height='19' />
      <rect x='851' y='275' rx='10' ry='10' width='85' height='19' />
      <rect x='851' y='338' rx='10' ry='10' width='85' height='19' />
      <rect x='851' y='400' rx='10' ry='10' width='85' height='19' />
      <rect x='851' y='460' rx='10' ry='10' width='85' height='19' />
      <rect x='851' y='520' rx='10' ry='10' width='85' height='19' />
      <rect x='851' y='580' rx='10' ry='10' width='85' height='19' />


      <rect x='978' y='98' rx='10' ry='10' width='169' height='19' />
      <rect x='977' y='155' rx='10' ry='10' width='169' height='19' />
      <rect x='977' y='217' rx='10' ry='10' width='169' height='19' />
      <rect x='977' y='275' rx='10' ry='10' width='169' height='19' />
      <rect x='977' y='338' rx='10' ry='10' width='169' height='19' />
      <rect x='977' y='400' rx='10' ry='10' width='169' height='19' />
      <rect x='977' y='460' rx='10' ry='10' width='169' height='19' />
      <rect x='977' y='520' rx='10' ry='10' width='169' height='19' />
      <rect x='977' y='580' rx='10' ry='10' width='169' height='19' />


      <rect x='1183' y='99' rx='10' ry='10' width='85' height='19' />
      <rect x='1182' y='156' rx='10' ry='10' width='85' height='19' />
      <rect x='1182' y='218' rx='10' ry='10' width='85' height='19' />
      <rect x='1182' y='276' rx='10' ry='10' width='85' height='19' />
      <rect x='1182' y='339' rx='10' ry='10' width='85' height='19' />
      <rect x='1182' y='400' rx='10' ry='10' width='85' height='19' />
      <rect x='1182' y='460' rx='10' ry='10' width='85' height='19' />
      <rect x='1182' y='520' rx='10' ry='10' width='85' height='19' />
      <rect x='1182' y='580' rx='10' ry='10' width='85' height='19' />


      <rect x='1305' y='97' rx='10' ry='10' width='85' height='19' />
      <rect x='1304' y='154' rx='10' ry='10' width='85' height='19' />
      <rect x='1304' y='216' rx='10' ry='10' width='85' height='19' />
      <rect x='1304' y='274' rx='10' ry='10' width='85' height='19' />
      <rect x='1304' y='340' rx='10' ry='10' width='85' height='19' />
      <rect x='1304' y='400' rx='10' ry='10' width='85' height='19' />
      <rect x='1304' y='460' rx='10' ry='10' width='85' height='19' />
      <rect x='1304' y='520' rx='10' ry='10' width='85' height='19' />
      <rect x='1304' y='580' rx='10' ry='10' width='85' height='19' />


      <circle cx='1456' cy='106' r='12' />
      <circle cx='1456' cy='163' r='12' />
      <circle cx='1456' cy='225' r='12' />
      <circle cx='1456' cy='283' r='12' />
      <circle cx='1456' cy='346' r='12' />
      <circle cx='1456' cy='406' r='12' />
      <circle cx='1456' cy='466' r='12' />
      <circle cx='1456' cy='526' r='12' />
      <circle cx='1456' cy='586' r='12' />
    </ContentLoader>
    </div>

  </div>
);

export {LogTableShimmer};
