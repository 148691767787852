import {FC} from 'react';
import {TimeHeader} from './TimeHeader';
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers';
import {AssetsConstant} from '../../../common/AssetsConstants';

type Props = {
  lastUpdate: Date;
  isForDetailPage?: boolean;
  pdfCallback: () => void;
  isCapturePDF: boolean;
};
const _buildExportPdfButton = (printCallback: () => void) => {
  return (
    <div
      className='btn ms-3 h-50px'
      style={{background: 'linear-gradient(93deg, #52525C 0%, black 100%)'}}
    >
      <div className='hstack gap-3'>
        <div className='fs-3 fw-bold text-white' onClick={printCallback}>
          Export PDF
        </div>
        <img alt='' className='w-30px' src={toAbsoluteUrl('/media/svg/files/pdf.svg')} />
      </div>
    </div>
  );
};
const DashboardHeader: FC<Props> = ({
  lastUpdate,
  isForDetailPage = false,
  pdfCallback,
  isCapturePDF = false,
}) => {
  const handlePrint = () => {
    pdfCallback();
  };
  return (
    <>
      <div className='container pt-5 mt-0 bg-gray-300'>
        <div className='row row-xl-10 align-items-center'>
          <div className='col col-xl-5 min-w-400px'>
            <TimeHeader lastUpdate={lastUpdate} />
          </div>
          {!isForDetailPage && !isCapturePDF && (
            <div className='col col-xl-5 min-w-250px'>
              <div className='btn bg-gray-100 h-50px pt-4'>
                <div className='hstack'>
                  <div className='fs-4 fw-bold text-muted'>Daily</div>
                  <i className='ms-5 fs-4'>
                    <KTSVG className='svg-icon-2x' path={toAbsoluteUrl(AssetsConstant.calendar)} />
                  </i>
                </div>
              </div>
              {_buildExportPdfButton(handlePrint)}
            </div>
          )}
          {isForDetailPage && !isCapturePDF && (
            <div className='col hstack'>
              <div className='ms-auto'>{_buildExportPdfButton(handlePrint)}</div>
            </div>
          )}{' '}
        </div>
      </div>
    </>
  );
};

export {DashboardHeader};
