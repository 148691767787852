import {Dashboard, TrainSetListDashboardItem} from '../../data/models/Dashboard';
import {mockDashboardData} from '../../data/models/TestData';
import {UserModel} from '../../presentation/modules/auth';
import {getUserByToken} from '../../presentation/modules/auth/core/_requests';
import {TrainSetDashboardUsecase} from './TrainSetDashboardUsecase';

const getAllData = async (): Promise<Dashboard> => {
  const trainsetResult = await TrainSetDashboardUsecase.getAll();
  return {
    ...mockDashboardData,
    tranSets: trainsetResult.map((val): TrainSetListDashboardItem => {
      if(val.carriages.length === 0){
        return {trainSet: undefined, trainSetCode: val.code, isError: false}
      }
      return {trainSet: val, trainSetCode: val.code, isError: false};
    }),
  };
};

const getProfile = async (): Promise<UserModel> => {
  return await getUserByToken();
};

export const DashboardUsecase = {
  getAllData,
  getProfile,
};
