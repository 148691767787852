import {FC} from 'react';
import {KTSVG} from '../../../_metronic/helpers';
import {useAuth} from '../../modules/auth';

export const LOGOUT_MODAL_ID = 'logout_modal';
export const LOGOUT_MODAL_TARGET = '#logout_modal';

const LogoutModal: FC = () => {
  const {logout} = useAuth();

  return (
    <>
      <div className='modal  fade' tabIndex={-1} id={LOGOUT_MODAL_ID}>
        <div className='modal-dialog modal-dialog-centered '>
          <div className='modal-content w-400px'>
            <div className='modal-header border-0 m-0'>
              <h3 className='modal-title text-gray-800'>Log Out Akun</h3>
              <div
                className='btn btn-icon btn-sm btn-dark ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen034.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body pt-0'>
              <p className='fs-4 text-gray-500 fw-bold mb-10'>Anda akan keluar akun?</p>
              <div className='row'>
                <button
                  type='button'
                  className='btn btn-danger fw-bold'
                  onClick={logout}
                  data-bs-dismiss='modal'
                >
                  Log Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export {LogoutModal};
