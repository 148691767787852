import {ITrainsetSetting} from './TrainsetSetting';

export const dummySettingsData: ITrainsetSetting[] = [
  {
    code: 'TS 1',
    name: 'K 123 999',
    hotboxSetting: {
      axle: {bottomSafe: 0, topSafe: 37, topWarning: 50},
      break: {bottomSafe: 0, topSafe: 45, topWarning: 60},
      wheel: {bottomSafe: 0, topSafe: 42, topWarning: 70},
    },
    rFIDSettings: [
      {
        rfId: '41241234jk234234jk23423',
        carriageName: 'MC1',
      },
      {
        rfId: 'fsdfjklsdsjq23r23r234234',
        carriageName: 'T1',
      },
    ],
  },
  {
    code: 'TS 2',
    name: 'K 123 666',
    hotboxSetting: {
      axle: {bottomSafe: 0, topSafe: 37, topWarning: 50},
      break: {bottomSafe: 0, topSafe: 45, topWarning: 60},
      wheel: {bottomSafe: 0, topSafe: 42, topWarning: 70},
    },
    rFIDSettings: [
      {
        rfId: '12312312312312366666666666',
        carriageName: 'MC2',
      },
      {
        rfId: 'fsdfjklsdsjq23r23r234234',
        carriageName: 'T2',
      },
    ],
  },
];
