import {FC} from 'react';
import {Link} from 'react-router-dom';
import {hotBoxRoutes} from '../../../common/Routes';
import {NotificationTimeStamp} from '../../components/time-component/NotificationTimeStamp';
import {TimeAgoIndicator} from '../../components/time-component/TimeAgoIndicator';
import {INotification} from '../../../data/models/notification/Notification';
import {NotificationUsecase} from '../../../domain/usecases/NotificationUsecase';

type TileProps = {
  notification: INotification;
};

const NotificationTile: FC<TileProps> = ({notification}) => {
  const updateRead = (id: string) => {
    NotificationUsecase.updateRead(id);
  };

  return (
    <>
      <div
        className={`card my-3 p-4 border-1 ${
          notification.isRead ? 'border-grey-400' : 'border-dark'
        }`}
      >
        <div className='hstack'>
          <div className='hstack gap-3'>
            <div className='card bg-danger-custom-1 border-danger flex-center rounded-circle w-50px h-50px fs-7 m-0 p-0'>
              <div className='fs-5 fw-bold text-white'>
                {notification.trainsetName.replaceAll(' ', '')}
              </div>
            </div>
            <div>
              <div className='fs-6 fw-bold text-dark'>{notification.trainsetName}</div>
              <NotificationTimeStamp date={notification.captureDate} />
            </div>
          </div>
          <div className='ms-auto'>
            <div className='hstack gap-0'>
              <TimeAgoIndicator date={notification.captureDate} />
              <Link
                to={{
                  pathname: hotBoxRoutes.trainsetLogDetailRoute.path,
                  search: `id=${notification.dataId}`,
                }}
              >
                <div>
                  <div
                    className='card bg-dark h-16px w-40px px-2'
                    onClick={() => {
                      updateRead(notification.id);
                    }}
                  >
                    <i
                      className={`d-flex justify-content-center bi bi-arrow-right fs-7 text-white my-3 p-0`}
                    ></i>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export {NotificationTile};
