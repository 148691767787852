import {FC} from 'react';
import {PageHeader} from '../../../components/PageHeader';
import {TabSettingCard} from './TabSettingCard';

const TrainsetSettingWrapper: FC = () => {
  return (
    <>
      <div className='container-xxl'>
        <div className='mt-7'></div>
        <PageHeader title='Pegaturan' />
        <div className='mt-10'></div>
        <TabSettingCard />
        <div className='separator mb-20 border-0'></div>
      </div>
    </>
  );
};

export {TrainsetSettingWrapper};
