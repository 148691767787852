/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {KTIcon} from '../../../helpers';
import {Stack} from 'react-bootstrap';

type Props = {
  className: string;
  color: string;
  svgIcon: string;
  iconColor: string;
  title: string;
  titleSize?: string;
  subTitle?: string;
  secondTitle?: string;
  titleColor?: string;
  description: string;
  descriptionColor?: string;
};

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleSize,
  subTitle,
  secondTitle,
  titleColor,
  description,
  descriptionColor,
}) => {
  return (
    <a className={`card card-stretch bg-${color} hoverable ${className} pt-4 pb-0 px-5`}>
      <div className='card-body p-0 pb-3'>
        <KTIcon iconName={svgIcon} className={`text-${iconColor} fs-3x ms-n1 mb-3`} />
        <Stack direction='horizontal' className='align-items-end'>
          <div className={`text-${titleColor} fw-bold ${titleSize ?? 'fs-1'}`}>{title}</div>
          <div className={`text-${titleColor} fs-7 mb-1 ms-1`}>{subTitle}</div>
        </Stack>
        {secondTitle && <div className={`text-${titleColor} fw-bold fs-4`}>{secondTitle}</div>}

        <div className={`fw-bold fs-7 mt-2 text-${descriptionColor}`}>{description}</div>
      </div>
    </a>
  );
};

export {StatisticsWidget5};
