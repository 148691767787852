import {FC, useEffect, useState} from 'react';
import {Stack} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {hotBoxRoutes} from '../../../common/Routes';
import {ITrainsetSetting} from '../../../data/models/settings/TrainsetSetting';
import {SettingUsecase} from '../../../domain/usecases/SettingUsecase';
import {InvalidDataComponent, InvalidDataType} from '../../components/InvalidDataComponent';
import {SettingConstant} from './SettingConstant';

const TrainSetSettingList: FC = () => {
  const [settingList, setSettingList] = useState<ITrainsetSetting[]>();

  useEffect(() => {
    setSettingList([]);
    const result = SettingUsecase.getAll();
    setSettingList(result);
  }, []);

  const _buildSettingCards = () => {
    const cards = [];
    for (let index = 0; index < settingList!.length; index++) {
      const element = settingList![index];
      cards.push(
        <Link
          key={index}
          to={{pathname: hotBoxRoutes.trainsetSettingRoute.path, search: `id=${element.code}`}}
        >
          <TrainSetSettingCard setting={element} />
        </Link>
      );
    }
    return cards;
  };

  return (
    <>
      <div className='card hover card-rounded card-bordered bg-white'>
        <div className='card-body'>
          <h5>List Trainset</h5>
          <div className='mt-7'>
            {settingList && _buildSettingCards()}
            {!settingList && <InvalidDataComponent type={InvalidDataType.NO_DATA} />}
          </div>

          <div className='mt-20 mb-5'>
            <Stack direction='horizontal'>
              <Link
                to={{
                  pathname: hotBoxRoutes.trainsetSettingRoute.path,
                  search: `id=TS ${(settingList?.length ?? 0) + 1}`,
                }}
                className='ms-auto'
              >
                <div className='btn btn-primary fw-bold mt-15'>{SettingConstant.addNewButton}</div>
              </Link>
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

const TrainSetSettingCard: FC<{setting: ITrainsetSetting}> = ({setting}) => {
  return (
    <>
      <div className='card card-rounded card-bordered border-2 bg-hover-light my-3'>
        <div className='card-body p-0 ps-8 pe-9 d-flex flex-row align-items-center'>
          <div className='d-flex flex-column justify-content-center h-85px'>
            <div className='fs-1 fw-bold'>{setting.code}</div>
            {setting.name && <div className='fs-3 text-gray-500'>{setting.name ?? '-'}</div>}
          </div>
          <i className={`ms-auto bi bi-chevron-right fs-2 text-black fw-bold`}></i>
        </div>
      </div>
    </>
  );
};

export {TrainSetSettingList};
