import {Dashboard, TrainSetListDashboardItem} from './Dashboard';
import {TrainSet} from './TrainSet';

export const mockTestData: TrainSet[] = [
  {
    code: 'K1 1 20 31',
    axileAvgTemperature: 32,
    wheelAvgTemperature: 31,
    brakeAvgTemperature: 40,
    speed: 120.34,
    overAllSummary: 'Tidak Aman',
    lastUpdate: new Date(Date.now()),
    direction: {destination: 'St. Bekasi Barat', origin: 'St. Jatimulya'},
    carriages: [
      {
        id: 'MC1 - K1 1 20 31',
        wheels: [
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 0, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
          },
        ],
      },
      {
        id: 'M1 - K1 1 20 31',
        wheels: [
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
        ],
      },
      {
        id: 'T1 - K1 1 20 31',
        wheels: [
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
          },
        ],
      },
      {
        id: 'T2 - K1 1 20 31',
        wheels: [
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
        ],
      },
      {
        id: 'M2 - K1 1 20 31',
        wheels: [
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
        ],
      },
      {
        id: 'MC2 - K1 1 20 31',
        wheels: [
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
        ],
      },
    ],
  },
  {
    code: 'K1 1 20 32',
    axileAvgTemperature: 32,
    wheelAvgTemperature: 31,
    brakeAvgTemperature: 32,
    speed: 120.34,
    overAllSummary: 'Aman',
    lastUpdate: new Date(Date.now()),
    direction: {destination: 'St. Bekasi Barat', origin: 'St. Jatimulya'},
    carriages: [
      {
        id: 'MC1 - K1 1 20 31',
        wheels: [
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
        ],
      },
      {
        id: 'M1 - K1 1 20 31',
        wheels: [
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
        ],
      },
      {
        id: 'T1 - K1 1 20 31',
        wheels: [
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 34, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 34, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
        ],
      },
      {
        id: 'T2 - K1 1 20 31',
        wheels: [
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
        ],
      },
      {
        id: 'M2 - K1 1 20 31',
        wheels: [
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
        ],
      },
      {
        id: 'MC2 - K1 1 20 31',
        wheels: [
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
          {
            isContainWarning: false,
            axle: {
              left: {temperature: 31, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
            wheel: {
              left: {temperature: 32, isOverHeat: false},
              right: {temperature: 31, isOverHeat: false},
            },
            brake: {
              left: {temperature: 33, isOverHeat: false},
              right: {temperature: 32, isOverHeat: false},
            },
          },
        ],
      },
    ],
  },
  {
    code: 'K1 1 20 33',
    axileAvgTemperature: 38,
    wheelAvgTemperature: 40,
    brakeAvgTemperature: 45,
    speed: 120.34,
    overAllSummary: 'Tidak Aman',
    lastUpdate: new Date(Date.now()),
    direction: {destination: 'St. Bekasi Barat', origin: 'St. Jatimulya'},
    carriages: [
      {
        id: 'MC1 - K1 1 20 31',
        wheels: [
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
        ],
      },
      {
        id: 'M1 - K1 1 20 31',
        wheels: [
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
        ],
      },
      {
        id: 'T1 - K1 1 20 31',
        wheels: [
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
        ],
      },
      {
        id: 'T2 - K1 1 20 31',
        wheels: [
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
        ],
      },
      {
        id: 'M2 - K1 1 20 31',
        wheels: [
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
        ],
      },
      {
        id: 'MC2 - K1 1 20 31',
        wheels: [
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
          {
            isContainWarning: true,
            axle: {
              left: {temperature: 40, isOverHeat: true},
              right: {temperature: 38, isOverHeat: true},
            },
            wheel: {
              left: {temperature: 40, isOverHeat: false},
              right: {temperature: 41, isOverHeat: false},
            },
            brake: {
              left: {temperature: 45, isOverHeat: true},
              right: {temperature: 49, isOverHeat: true},
            },
          },
        ],
      },
    ],
  },
];

const dummyAllTrainsets = [
  ...mockTestData.map((val): TrainSetListDashboardItem => {
    return {trainSet: val, trainSetCode: val.code, isError: false};
  }),
  {trainSetCode: 'TS no-data', isError: false},
  {trainSetCode: 'TSwarning', isError: true},
  ...mockTestData.map((val): TrainSetListDashboardItem => {
    return {trainSet: val, trainSetCode: val.code, isError: false};
  }),
  ...mockTestData.map((val): TrainSetListDashboardItem => {
    return {trainSet: val, trainSetCode: val.code, isError: false};
  }),
  ...mockTestData.map((val): TrainSetListDashboardItem => {
    return {trainSet: val, trainSetCode: val.code, isError: false};
  }),
  ...mockTestData.map((val): TrainSetListDashboardItem => {
    return {trainSet: val, trainSetCode: val.code, isError: false};
  }),
  ...mockTestData.map((val): TrainSetListDashboardItem => {
    return {trainSet: val, trainSetCode: val.code, isError: false};
  }),
].map((value, index) => {
  return {...value, trainSetCode: `TS ${index + 1}`};
});
export const mockDashboardData: Dashboard = {
  summary: {
    amountCheckedTrainSet: 21,
    numberOfAllTrainSet: 31,
    safetinessPercentation: 100,
    needMaintenanceTrainSet: 11,
    panelTemperature: 36,
    axleSummaryTemperature: 31,
    brakeSummaryTemperature: 35,
    wheelSummaryTemperature: 32,
    topFiveIncident: [
      {description: 'TS1', times: 12},
      {description: 'TS2', times: 10},
      {description: 'TS3', times: 8},
      {description: 'TS4', times: 6},
      {description: 'TS5', times: 4},
    ],
    topIncidentByCarriages: [
      {description: 'MC1 - K1 1 20 32', times: 12},
      {description: 'M1 - K1 1 12 33', times: 10},
      {description: 'MC1 - K1 1 20 11', times: 8},
      {description: 'T1 - K1 1 20 30', times: 6},
      {description: 'MC1 - K1 1 20 32', times: 4},
    ],
  },
  tranSets: dummyAllTrainsets,
  lastUpdate: new Date(1702344629000),
};
