import {useIntl} from 'react-intl';
import {PageTitle} from '../../../_metronic/layout/core';
import {TrainSetDashboard} from './TrainSetDashboard/TrainSetDashboard';
import {OverAllDashboard} from './OverAllDashboard/OverAllDashboard';
import {Dashboard} from '../../../data/models/Dashboard';
import {useEffect, useRef, useState} from 'react';
import {DashboardUsecase} from '../../../domain/usecases/DashboardUsecase';
import {InvalidDataComponent, InvalidDataType} from '../../components/InvalidDataComponent';
import {DashboardHeader} from './DashboardHeader';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {PDF_SETUP} from '../../../common/CommonConstants';
import {NotificationUsecase} from '../../../domain/usecases/NotificationUsecase';
import {INotification} from '../../../data/models/notification/Notification';
import {NotificationBanner} from '../notification/NotificationBanner';

const DashboardWrapper = () => {
  const [data, setData] = useState<Dashboard>();
  const [isCapturePDF, setIsCapturePDF] = useState<boolean>(false);
  const notificationData = useRef<INotification[]>([]);
  const intl = useIntl();
  const [isShowNotif, setIsShowNotif] = useState<boolean>(false);

  const fetch = async () => {
    const dashboardData = await DashboardUsecase.getAllData();
    setData(dashboardData);
  };

  useEffect(() => {
    fetch();
    const interval = setInterval(() => {
      _showNotif();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const _generatePDF = async () => {
    setIsCapturePDF(true);
    setTimeout(async () => {
      await html2canvas(document.querySelector('#capture')!)
        .then((canvas: any) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF(PDF_SETUP);
          pdf.addImage(imgData, 'PNG', 0, 0, 0, 0, 'log', 'NONE', 0);
          pdf.save('log.pdf');
        })
        .catch((e: any) => {
          console.log('error', e);
        });
      setIsCapturePDF(false);
    }, 1000);
  };

  const _showNotif = () => {
    const newNotification = NotificationUsecase.getAll();
    if (notificationData.current.length < newNotification.length) {
      notificationData.current = [...newNotification];
      setIsShowNotif(true);
      setTimeout(() => {
        setIsShowNotif(false);
      }, 3000);
    }
  };

  const content = [];
  if (data) {
    content.push(
      <div id='capture' key={'loaded_page'} className='ribbon ribbon-top ribbon-vertical'>
        <NotificationBanner
          isShowNotif={isShowNotif}
          notificationDate={
            notificationData.current[notificationData.current.length - 1]?.captureDate
          }
        />
        <DashboardHeader
          key={'header'}
          lastUpdate={data.lastUpdate}
          pdfCallback={_generatePDF}
          isCapturePDF={isCapturePDF}
        />
        <OverAllDashboard key={'overall'} data={data.summary} />
        <TrainSetDashboard key={'dashboard'} data={data.tranSets} />
      </div>
    );
  } else {
    content.push(
      <div key={'loading_page'} className='content h-500px pt-20'>
        <InvalidDataComponent
          key={'loading_component'}
          type={InvalidDataType.DATA_STILL_PROCESSING}
        />
      </div>
    );
  }
  return (
    <>
      <PageTitle key={'page_title_dashboard'} breadcrumbs={[]}>
        {intl.formatMessage({id: 'MENU.DASHBOARD'})}
      </PageTitle>
      {content}
    </>
  );
};

export {DashboardWrapper};
