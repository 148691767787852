import { HotboxAppLicense, dummyHotboxAppLicenseData } from '../../data/models/settings/HotboxAppLicense';
import {ITrainsetSetting} from '../../data/models/settings/TrainsetSetting';
import {SettingRepository} from '../../data/repositories/SettingRepository';

const getAll = (): ITrainsetSetting[] => {
  return SettingRepository.getAll();
};

const getByTrainsetCode = (code: string): ITrainsetSetting | undefined => {
  return SettingRepository.getByTrainsetCode(code);
};

const upsert = (newSetting: ITrainsetSetting) => {
  SettingRepository.upsert(newSetting);
};

const getLicense = (): HotboxAppLicense =>{
  return dummyHotboxAppLicenseData;
}

export const SettingUsecase = {
  getAll,
  getByTrainsetCode,
  upsert,
  getLicense,
};
