import {FC, useEffect, useState} from 'react';
import {SettingUsecase} from '../../../domain/usecases/SettingUsecase';
import {HotboxAppLicense} from '../../../data/models/settings/HotboxAppLicense';

const LicenseWrapper: FC = () => {
  const [licenseData, setLicenseData] = useState<HotboxAppLicense>();

  useEffect(() => {
    const result = SettingUsecase.getLicense();
    setLicenseData(result);
  }, []);

  return (
    <>
      <div className='card bg-gray-200 border-0 my-7 p-7'>
        <div className='row row-xxl-12 p-5'>
          <div className='col col-xxl-2'>
            <div className='fs-4 fw-bold text-gray-700'>Dashboard Version</div>
            <div className='fs-4 fw-bold text-gray-700 mt-10'>Last Update</div>
            <div className='fs-4 fw-bold text-gray-700 mt-10'>Activation</div>
          </div>
          <div className='col col-xxl-10'>
            <div className='fs-4 fw-normal text-gray-700'>{licenseData?.dashboardVersion}</div>
            <div className='fs-4 fw-normal text-gray-700 mt-10'>
              {licenseData?.lastUpdate.toDateString()}
            </div>
            <div className='fs-4 fw-normal text-gray-700 mt-10'>
              Dashboard is activated with a license until{' '}
              <b>{licenseData?.activeDate.toDateString()}</b>
            </div>
            <a
              className='fs-4 fw-bold text-gray-700 mt-2 underline'
              href={`${licenseData?.documentUrl}`}
              target='_blank'
              rel='noreferrer'
            >
              <u>Download Link</u>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export {LicenseWrapper};
