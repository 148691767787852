/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import {useFormik} from 'formik';
import {login} from '../core/_requests';
import {useAuth} from '../core/Auth';
import {toAbsoluteUrl} from '../../../../_metronic/helpers';
import {AssetsConstant} from '../../../../common/AssetsConstants';
import { authResponseToModel, authToUserModel } from '../core/AuthHelpers';

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
});

const initialValues = {
  username: 'admin',
  password: 'hellokai',
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const {saveAuth, setCurrentUser} = useAuth();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true);
      try {
        const auth = await login(values.username, values.password);
        const authModel = authResponseToModel(auth);
        saveAuth(authModel);
        const user = authToUserModel(auth);
        setCurrentUser(user);
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus('The login details are incorrect');
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center'>
        <div className='mb-0'>
          <img
            className='w-325px'
            src={toAbsoluteUrl(AssetsConstant.imamAuthLogo)}
            alt=''
            height='fit-content'
          />
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-primary p-8 rounded'>
          <div className='text-primary'>
            Use account <strong>this account below</strong> to continue.
          </div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
        <input
          placeholder='Username'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control bg-transparent',
          )}
          type='username'
          name='username'
          autoComplete='off'
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.username}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-flex flex-start mb-20 mt-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-dark min-w-100px h-40px fw-bold'
          disabled={formik.isSubmitting || !formik.isValid}
          style={{background: 'linear-gradient(93deg, #52525C 0%, black 100%)'}}
        >
          {!loading && <span className='indicator-label'>Login</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-center flex-wrap gap-3 fs-base fw-semibold mb-1'>
        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-dark fw-bold text-center'>
          Lupa Password ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}
    </form>
  );
}
