import {dummySettingsData} from '../models/settings/DummySettingData';
import {ITrainsetSetting} from '../models/settings/TrainsetSetting';

const settingDataStore: ITrainsetSetting[] = [...dummySettingsData];

const getAll = () => {
  return settingDataStore;
};

const getByTrainsetCode = (code: string) => {
  return settingDataStore.find((element) => element.code === code);
};

const upsert = (newSetting: ITrainsetSetting) => {
  const index = settingDataStore.findIndex((element) => element.code === newSetting.code);
  if (index < 0) {
    settingDataStore.push(newSetting);
  }
  settingDataStore[index] = newSetting;
};

export const SettingRepository = {
  getAll,
  getByTrainsetCode,
  upsert,
};
