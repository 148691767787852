import {FC} from 'react';

type Props = {
  date: Date;
};
const AGO = ' yang lalu';
const textConversion = {
  seconds: ' detik',
  minutes: ' menit',
  hours: ' jam',
  days: ' hari',
  weeks: ' minggu',
  months: ' bulan',
  years: ' tahun',
};
const unitConversion = {
  seconds: 1,
  minutes: 60,
  hours: 3600,
  days: 86400,
  weeks: 604800,
  months: 2628000,
  years: 31557600,
};

const getAmountDiff = (diff: number, divider: number) => {
  return Math.floor(diff / divider);
};
const getTimeAgoText = (date: Date): string => {
  const now = Math.floor(Date.now() / 1000);
  const dataTime = Math.floor(date.getTime() / 1000);
  const diff = now - dataTime;
  let text = '';
  const units: number[] = [
    unitConversion.years,
    unitConversion.months,
    unitConversion.weeks,
    unitConversion.days,
    unitConversion.hours,
    unitConversion.minutes,
    unitConversion.seconds,
  ];
  const texts: string[] = [
    textConversion.years,
    textConversion.months,
    textConversion.weeks,
    textConversion.days,
    textConversion.hours,
    textConversion.minutes,
    textConversion.seconds,
  ];
  let index = 0;
  try {
    while (!(diff >= units[index])) {
      index++;
      if (index === units.length - 1) {
        break;
      }
    }
    text += getAmountDiff(diff, units[index]);
    text += texts[index];
  } catch (e) {
    console.log('error', e);
  }
  return text + AGO;
};
const TimeAgoIndicator: FC<Props> = ({date}) => {
  return (
    <>
      <div className='w-100px fs-7 text-gray-600'>{getTimeAgoText(date)}</div>
    </>
  );
};

export {TimeAgoIndicator};
