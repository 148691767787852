import {FC, useState} from 'react';
import {Stack} from 'react-bootstrap';

type TimerIndicatorProps = {
  seconds: number;
  isRun: boolean;
  onTap?: () => void;
};

const TimerIndicator: FC<TimerIndicatorProps> = ({seconds, isRun, onTap}) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = (isHover: boolean) => {
    setIsHovered(isHover);
  };
  const handleOnClick = () => {
    onTap?.();
  };

  const getClassName = () => {
    const borderColor = isRun || isHovered ? 'border-primary' : 'border-warning';
    const baseClassName = `btn d-flex border border-4 ${borderColor} flex-center rounded-circle w-60px h-60px fs-7 m-0 p-0`;
    if (isHovered) {
      return baseClassName + `text-white bg-primary`;
    }
    return baseClassName + 'text-black bg-white';
  };

  const getContent = () => {
    if (isHovered) {
      return <TimerOperationPresentation isRun={isRun} />;
    }
    return <TimerPresentation seconds={seconds} isRun={isRun} />;
  };
  return (
    <>
      <button
        className={getClassName()}
        onClick={handleOnClick}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
      >
        {getContent()}
      </button>
    </>
  );
};

const TimerPresentation: FC<TimerIndicatorProps> = ({seconds, isRun}) => {
  if (isRun) {
    return (
      <>
        <span className='fs-1 fw-bolder'>{seconds}</span>
        <span className='pt-2 fw-100'>s</span>
      </>
    );
  }
  return (
    <>
      <div>
        <Stack direction='vertical'>
          <i className={`bi bi-pause-fill fs-2qx text-warning`}></i>
          <div>
            <span className='fs-5 fw-100 lh-1'>{seconds}</span>
            <span className='fs-8 fw-100'>s</span>
          </div>
        </Stack>
      </div>
    </>
  );
};

const TimerOperationPresentation: FC<{isRun: boolean}> = ({isRun}) => {
  const icon = isRun ? 'bi-pause-fill' : 'bi-play-fill';
  const text = isRun ? 'Pause' : 'Continue';
  return (
    <>
      <div>
        <Stack direction='vertical'>
          <i className={`bi ${icon} fs-2qx text-white mb-0 pb-0`}></i>
          <span className='fs-9 text-white lh-1'>{text}</span>
        </Stack>
      </div>
    </>
  );
};

export {TimerIndicator};
