import { WheelDetail, toWheelDetailFromResponse } from "./WheelDetail"
import { TemperatureCarResponse } from "./response/TrainsetResponse"

export interface TrainCarrieges {
    id: string
    wheels: WheelDetail[]
}

export function toTrainCarriegesFromResponse(response: TemperatureCarResponse): TrainCarrieges{
    return {
        id: response.car_name,
        wheels: [
            toWheelDetailFromResponse(response.wheel_1),
            toWheelDetailFromResponse(response.wheel_2),
            toWheelDetailFromResponse(response.wheel_3),
            toWheelDetailFromResponse(response.wheel_4),
        ],
    }
}