import {TrainCarrieges, toTrainCarriegesFromResponse} from './TrainCarriages';
import {TemperatureCarResponse, TrainsetResponse} from './response/TrainsetResponse';

export interface TrainDirection {
  origin: string;
  destination: string;
}

export interface TrainSet {
  code: string;
  axileAvgTemperature: number;
  wheelAvgTemperature: number;
  brakeAvgTemperature: number;
  overAllSummary: string;
  direction: TrainDirection;
  carriages: TrainCarrieges[];
  speed: number;
  lastUpdate: Date;
}

function getCarIndex(type: string): number {
  const carOrder = {MC1: 0, M1: 1, T1: 2, T2: 3, M2: 4, MC2: 5};
  return carOrder[type as keyof typeof carOrder];
}

export function toTrainsetFromResponse(response: TrainsetResponse): TrainSet {
  const orderedCar: TemperatureCarResponse[] = [];
  for (let car of response.trainset_temperature_car ?? []) {
    const index = getCarIndex(car.car_type);
    orderedCar[index] = car;
  }
  return {
    code: response.trainset_name,
    axileAvgTemperature: response.avg_axle,
    wheelAvgTemperature: response.avg_wheel,
    brakeAvgTemperature: response.avg_brake,
    speed: response.km_per_hour,
    overAllSummary: response.is_trainset_alarmed ? 'Waspada': 'Aman',
    direction: {destination: 'St. Bekasi Barat', origin: 'St. Jatimulya'},
    carriages: orderedCar.map((carResponse) => toTrainCarriegesFromResponse(carResponse)),
    lastUpdate: new Date(response.last_update_at)
  };
}
