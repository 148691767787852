import {FC} from 'react';
import {KTIcon} from '../../_metronic/helpers';
import {TrainDirection} from '../../data/models/TrainSet';

type TripStatusCardProps = {
  className: string;
  trip: TrainDirection;
};

const TripStatusCard: FC<TripStatusCardProps> = ({className, trip}) => {
  return (
    <>
      <div className={`card ${className} p-0`}>
        <div className='card-body py-5 px-5'>
          <div className='row row-sm-10 align-items-center pb-5'>
            <div className='col col-sm-9 pb-1'>
              <span className='fs-6 fw-bold mb-0 text-dark'>Asal Stasiun</span>
            </div>
            <div className='col col-sm-1'>
              <KTIcon iconName='category' className='fs-3 text-primary' />
            </div>
          </div>
          <div className='ps-1'>
            <div className='timeline-label'>
              <div className='timeline-item'>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-warning fs-1 pt-0'></i>
                </div>
                <div className='fs-7 timeline-content text-muted ps-4'>{trip.origin}</div>
              </div>
              <div className='timeline-item pt-1'>
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-success fs-1 pt-2'></i>
                </div>
                <div className='timeline-content d-flex'>
                  <span className='fs-7 fw-bold text-gray-800 ps-4 pt-1'>{trip.destination}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export {TripStatusCard};
