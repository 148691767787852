import {FC} from 'react';
import {TripStatusCard} from './TripStatusCard';
import {StatisticsWidget5} from '../../_metronic/partials/widgets';
import {TrainSet} from '../../data/models/TrainSet';

type TrainSetSummaryProps = {
  trainSet?: TrainSet;
};

const TrainSetSummary: FC<TrainSetSummaryProps> = ({trainSet}) => {
  if (trainSet)
    return (
      <>
        <div className='row g-3 pb-2'>
          <div className='col-sm-2'>
            <StatisticsWidget5
              className='card-xl-stretch mb-3'
              svgIcon='abstract'
              color='body-white'
              iconColor='primary'
              title={`${trainSet.speed}`}
              subTitle='km/jam'
              description='Kecepatan Kereta'
              titleColor='gray-900'
              descriptionColor='gray-400'
            />
          </div>
          <div className='col-sm-2'>
            <StatisticsWidget5
              className='card-xl-stretch mb-3'
              svgIcon='chart-simple'
              color='body-white'
              iconColor='primary'
              title={trainSet.axileAvgTemperature.toFixed(2).toString() + '°c'}
              description='Rata-Rata suhu Axle'
              titleColor='gray-900'
              descriptionColor='gray-400'
            />
          </div>

          <div className='col-sm-2'>
            <StatisticsWidget5
              className='card-xl-stretch mb-3'
              svgIcon='abstract-32'
              color='body-white'
              iconColor='primary'
              title={trainSet.wheelAvgTemperature.toFixed(2).toString() + '°c'}
              description='Rata-rata Suhu Wheel'
              titleColor='gray-900'
              descriptionColor='gray-400'
            />
          </div>

          <div className='col-sm-2'>
            <StatisticsWidget5
              className='card-xl-stretch mb-3'
              svgIcon='information-3'
              color='body-white'
              iconColor='primary'
              title={trainSet.brakeAvgTemperature.toFixed(2).toString() + '°c'}
              description='Rata-rata Suhu Brake'
              titleColor='gray-900'
              descriptionColor='gray-400'
            />
          </div>
          <div className='col-sm-2'>
            <StatisticsWidget5
              className='card-xl-stretch mb-3'
              svgIcon='abstract'
              color='body-white'
              iconColor='primary'
              title={trainSet.overAllSummary}
              description='Status Keseluruhan'
              titleColor='gray-900'
              descriptionColor='gray-400'
            />
          </div>
          <div className='col-sm-2'>
            <TripStatusCard className='card-xl-stretch mb-3 pb-5' trip={trainSet.direction} />
          </div>
        </div>
      </>
    );
  return <></>;
};
export {TrainSetSummary};
