import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Stack} from 'react-bootstrap';
import {KTSVG} from '../../../../_metronic/helpers';
import {CommonConstant} from '../../../../common/CommonConstants';
import {RFIDSide} from '../../../../data/models/enums/RFIDSide';
import {IRFIDSetting} from '../../../../data/models/settings/RFIDSetting';
import {SubmitState} from './TabSettingCard';
import {SubmitButton} from '../../../components/button/SubmitButton';

enum Event {
  initial = 'init',
  updateRFID = 'update',
  submit = 'submit',
  addNewRFID = 'add-new',
}

type RFIDtype = {
  settings?: IRFIDSetting[];
  onSubmit: (rFIDSettings: IRFIDSetting[]) => void;
  isLoading: boolean;
  submitState: SubmitState;
};

const RFIDForm: FC<RFIDtype> = ({settings = [], onSubmit, isLoading, submitState}) => {
  const [event, setEvent] = useState<string>(Event.initial);
  const rFIDsettings = useRef(settings);
  const iteration = useRef(0);
  const settingDefault: IRFIDSetting = useMemo(() => {
    return {
      rfId: '',
      carriageName: '',
      side: RFIDSide.RIGHT,
    };
  }, []);

  useEffect(() => {
    iteration.current = iteration.current + 1;
    setEvent(Event.initial);
  }, [event]);

  useEffect(() => {
    if (rFIDsettings.current.length === 0) {
      rFIDsettings.current = settings;
    }
  }, [settings]);

  const addNewAction = useCallback(() => {
    rFIDsettings.current = [...rFIDsettings.current, settingDefault];
    setEvent(Event.addNewRFID);
  }, [settingDefault]);

  const buildSetting = () => {
    const components = [];
    let loadedSetting = rFIDsettings.current;
    if (loadedSetting.length === 0) {
      loadedSetting = settings;
    }
    for (let index = 0; index < loadedSetting.length; index++) {
      components.push(
        <RFIDFormCard
          key={index}
          index={index}
          setting={loadedSetting[index]}
          onChange={(newSetting) => {
            rFIDsettings.current[index] = newSetting;
            setEvent(Event.updateRFID + `${iteration.current}`);
          }}
        />
      );
    }
    return components;
  };

  const onSaveClick = useCallback(() => {
    console.log(rFIDsettings.current);
    onSubmit(rFIDsettings.current);
  }, [onSubmit]);


  const _generateIsValidCondition = (): boolean =>{
    let loadedSetting = rFIDsettings.current;
    if (loadedSetting.length === 0) {
      loadedSetting = settings;
    }
    if(loadedSetting.length !== 0){
      let setting = loadedSetting[loadedSetting.length - 1];
      return setting.carriageName !== '' && setting.rfId !== '';
    }
    return false;
  }
  

  return (
    <>
      <div>
        {[...buildSetting()]}
        <div className='mt-7 mb-10'>
          <Stack direction='horizontal' gap={5}>
            <div
              className='btn btn-outline btn-white text-muted fw-bold ms-auto px-5'
              onClick={addNewAction}
            >
              <div className='hstack gap-2'>
                <KTSVG
                  path='/media/icons/duotune/general/gen035.svg'
                  className='svg-icon-dark svg-icon-2x'
                />
                <div className='ms-auto'>{CommonConstant.buttonAddRFID}</div>
              </div>
            </div>
            <SubmitButton isLoading={isLoading} state={submitState} onClick={onSaveClick} isDataValid={_generateIsValidCondition()}/>
          </Stack>
        </div>
      </div>
    </>
  );
};

type RFIDCardType = {
  index: number;
  setting: IRFIDSetting;
  onChange: (setting: IRFIDSetting) => void;
};

const RFIDFormCard: FC<RFIDCardType> = ({index, setting, onChange}) => {
  return (
    <>
      <div className='card bg-gray-200 border-0 my-7 p-7'>
        <div className='row row-xxl-12'>
          <div className='col col-xxl-2'>
            <div className='fs-3 fw-bold text-gray-700'>RFID {index + 1}</div>
            <div className='fs-3 fw-bold text-gray-700 mt-15'>Gerbong</div>
          </div>
          <div className='col col-xxl-10'>
            <input
              className='form-control'
              placeholder='RFID'
              aria-label='RFID'
              height={48}
              onChange={(event) => {
                onChange({...setting, rfId: event.target.value});
              }}
              value={setting.rfId}
            />
            <input
              className='form-control mt-7'
              placeholder='Nama Gerbong'
              aria-label='Gerbong Name'
              height={48}
              onChange={(event) => {
                onChange({...setting, carriageName: event.target.value});
              }}
              value={setting.carriageName}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export {RFIDForm};
