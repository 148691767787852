import {FC} from 'react';
import {TrainSetTimeLog} from '../../../data/models/TrainSetTimeLog';
import {getColorStatusData, getColorStatusDefinition} from '../../../common/Utils';
import {Link} from 'react-router-dom';
import {hotBoxRoutes} from '../../../common/Routes';

type RowDataProp = {
  log: TrainSetTimeLog;
};
const LogRowData: FC<RowDataProp> = ({log}) => {
  return (
    <>
      <tr className='text-gray-700'>
        <td className='text-center w-150px'>{log.reportDate.toLocaleString('id')}</td>
        <td className='text-center'>{log.trainSetCode}</td>
        <td className='text-center w-150px'>
          {log.lastMaintenanceDate.toLocaleDateString('id', {dateStyle: 'medium'})}
        </td>
        <td className='text-center'>{log.axleTemperatureSum}°c</td>
        <td className='text-center'>{log.wheelTemperatureSum}°c</td>
        <td className='text-center'>{log.brakeTemperatureSum}°c</td>
        <td className='text-center w-150px'>
          <div className='row'>
            <div className='col d-flex justify-content-center'>
              <div
                className={`card bg-${getColorStatusDefinition(
                  log.status
                )} py-1 px-3 text-white fw-bold`}
              >
                {log.status}
              </div>
            </div>
          </div>
        </td>
        <td className='text-center w-150px'>
          <div className='row'>
            <div className='col d-flex justify-content-center'>
              <div
                className={`card bg-${getColorStatusData(
                  log.statusData
                )} py-1 px-3 text-white align-items-center fw-bold`}
              >
                {log.statusData}
              </div>
            </div>
          </div>
        </td>
        <td className='text-center w-150px'>
          <Link to={{pathname: hotBoxRoutes.trainsetLogDetailRoute.path, search: `id=${log.trainSetId}`}}>
            <div className='btn btn-bg-dark text-white py-1 px-3 m-0 fw-bold'>Lihat Detail</div>
          </Link>
        </td>
      </tr>
    </>
  );
};

type LogTableProps = {
  logs: TrainSetTimeLog[];
};

const LogTable: FC<LogTableProps> = ({logs}) => {
  const logComponent = [];
  let i = 0;
  for (const log of logs) {
    i++;
    logComponent.push(<LogRowData key={`${i}`} log={log} />);
  }
  return (
    <>
      <div className='card p-0 m-0 min-w-500px'>
        <div className='card-body p-0 m-0'>
          <div className='table-responsive'>
            <table className='table align-middle table-row-bordered gy-2 gs-7 text-align-center'>
              <thead className='bg-gray-912 align-middle'>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                  <th className='text-center rounded-start'>Report Date</th>
                  <th className='text-center'>#Trainset</th>
                  <th className='text-center'>Perawatan Terakhir</th>
                  <th className='text-center'>Suhu Rata-rata Axle</th>
                  <th className='text-center'>Suhu Rata-rata Wheel</th>
                  <th className='text-center'>Suhu Rata-rata Brake</th>
                  <th className='text-center'>Definisi Status</th>
                  <th className='text-center'>Status</th>
                  <th className='text-center'>Detail</th>
                </tr>
              </thead>
              <tbody>{logComponent}</tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export {LogTable};
