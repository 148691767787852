export enum StatusDefinition {
  SAFE = 'Aman',
  WARNING = 'Peringatan',
  DANGER = 'Bahaya',
  IN_PROCESS = 'In Process',
  EMPTY = 'Kosong',
}

export enum StatusData {
  DONE = 'Done Capture',
  IN_PROCESS = 'In Process',
  FAILED = 'Failed',
}
