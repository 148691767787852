import axios from 'axios';
import {mockTimeLogData} from '../models/TestLogData';
import { DefaultResponse } from '../models/response/DefaultResponse';
import { TrainsetResponse } from '../models/response/TrainsetResponse';
import { TEMPERATURE_BY_ID_URL } from './UrlConstant';

const getAll = () => {
  return mockTimeLogData;
};

const getByTrainsetId = async (id: string): Promise<TrainsetResponse> => {
  const url = TEMPERATURE_BY_ID_URL+`/${id}`;
  const result = await axios.get<DefaultResponse<TrainsetResponse[]>>(url);
  return result.data.data[0];
};

export const TrainSetTimeLogRepository = {
  getAll,
  getByTrainsetId
};
