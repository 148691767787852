import {FC} from 'react';
import {KTIcon} from '../../_metronic/helpers';
import {Stack} from 'react-bootstrap';

export interface TopFiveDataModel {
  title: string;
  description: string;
  data: Data[];
}
interface Data {
  dataKey: string;
  value: number;
}
type TopFiveDataProps = {
  model: TopFiveDataModel;
};

const TopFiveDataComponent: FC<TopFiveDataProps> = ({model}) => {
  const tableRows = [];
  for (let i = 0; i < model.data.length; i++) {
    const data = model.data[i];
    tableRows.push(<BuildRow key={i} index={i} dataKey={data.dataKey} value={data.value} />);
  }
  return (
    <div className={`card card-stretch p-2 mb-3`}>
      {/* begin::Header */}
      <div className='card-header border-0 p-3 pt-3 pb-0'>
        <div className='row align-items-center justify-content-between w-300px'>
          <div className='col'>
            <h3>
              <div className='fw-bold fs-4 mb-1'>{model.title}</div>
              <div className='text-muted mt-1 fw-semibold fs-7'>{model.description}</div>
            </h3>
          </div>
          <div className='col-2'>
            <KTIcon iconName='category' className='fs-3 text-primary' />
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body p-4 pt-0'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-300 align-middle gy-3'>
            {/* begin::Table body */}
            <tbody>{tableRows}</tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

type RowProps = {
  index: number;
  dataKey: string;
  value: number;
};

const BuildRow: FC<RowProps> = ({index, dataKey, value}) => {
  return (
    <>
      <tr>
        <td>
          <Stack direction='horizontal'>
            <div className='card bg-gray-300 mb-2 px-1 py-0 me-3'>
              <div className='fw-bold text-muted fs-7'>{index + 1}</div>
            </div>
            <div className='text-dark fw-bold text-hover-primary mb-1 fs-7'>{dataKey}</div>
          </Stack>
        </td>
        <td className='text-end text-dark fw-bold fs-7 pe-0'>{value} Kali</td>
      </tr>
    </>
  );
};
export {TopFiveDataComponent};
